import React, { useEffect, useRef, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import { BiSolidSend } from "react-icons/bi";
import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import { FaRegCopy } from "react-icons/fa6";
import {
  RiUser3Line,
  RiOpenaiFill,
  RiMenuFoldLine,
  RiMenuUnfoldLine,
  RiCloseLine,
} from "react-icons/ri";
import ReactMarkdown from "react-markdown";
import "./index.css";

function Chatbot({
  isTyping,
  setIsTyping,
  messages,
  setMessages,
  chatData,
  newBot,
  chats,
  currentChatId,
  setCurrentChatId,
  setNewChat,
  setGetNewChat,
}) {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  const [showChats, setShowChats] = useState(true);
  const [showMobileChats, setShowMobileChats] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [messages]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (inputValue.trim() !== "") {
      handleSendMessage(inputValue);
      setInputValue("");
    }
  };
  const handleSendMessage = (messageContent) => {
    setNewChat(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: messageContent },
    ]);
    //invoke chatData
    setIsTyping(true);
    chatData(messageContent);
  };

  const handleButtonCopyClick = (message) => {
    navigator.clipboard.writeText(message);
  };

  const handleChangeChat = (chatId) => {
    setCurrentChatId(chatId);
    setGetNewChat(true);
    setNewChat(false);
    setMessages([]);
    const selectedChat = chats.find((chat) => chat.id === chatId);
    if (
      selectedChat &&
      Array.isArray(selectedChat.messages) &&
      selectedChat.messages !== null
    ) {
      setMessages(selectedChat.messages);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <div className="listChats" style={{ width: !showChats ? "auto" : "" }}>
          <div className="my-flex-container">
            {" "}
            {showChats && (
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  newBot();
                  setInputValue("");
                }}
                style={{ width: "130px" }}
                startIcon={<RiOpenaiFill />}
              >
                New Chat
              </Button>
            )}
            <div style={{ display: "grid" }}>
              <IconButton onClick={() => setShowChats(!showChats)}>
                {" "}
                {showChats ? <RiMenuFoldLine /> : <RiMenuUnfoldLine />}{" "}
              </IconButton>
              {!showChats && (
                <IconButton
                  onClick={() => {
                    newBot();
                    setInputValue("");
                  }}
                >
                  <RiOpenaiFill />
                </IconButton>
              )}
            </div>
          </div>

          {showChats && (
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <Stack spacing={1}>
                <div className="scrollBar-ListChats">
                  {chats
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((chat, index) => (
                      <Button
                        fullWidth
                        variant="text"
                        onClick={() => handleChangeChat(chat.id)}
                        sx={{ justifyContent: "flex-start" }}
                        style={{
                          borderLeft:
                            currentChatId === chat.id
                              ? "2px solid #1976d2"
                              : "none",
                          borderRadius: "0px",
                        }}
                      >
                        {chat.content.length > 18
                          ? chat.content.slice(0, 21) + "..."
                          : chat.content}
                      </Button>
                    ))}
                </div>
              </Stack>
            </Box>
          )}
        </div>
      )}
      <div style={{ width: "100%" }}>
        <div className={isMobile ? "divAIMobile" : "divAI"}>
          {isMobile && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 0px 0px 10px",
              }}
            >
              <IconButton onClick={() => setShowMobileChats(!showMobileChats)}>
                {showMobileChats ? <RiCloseLine /> : <RiMenuUnfoldLine />}
              </IconButton>
              <IconButton
                onClick={() => {
                  newBot();
                  setInputValue("");
                }}
              >
                <RiOpenaiFill />
              </IconButton>
            </div>
          )}
          {showMobileChats && isMobile && (
            <div
              className="listChatsMobile"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "whitesmoke",
                zIndex: 1000,
              }}
            >
              <Box sx={{ width: "100%", marginTop: "1rem" }}>
                <Stack spacing={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="h6">Chats</Typography>
                    <IconButton onClick={() => setShowMobileChats(false)}>
                      <RiMenuFoldLine />
                    </IconButton>
                  </div>
                  <div className="scrollBar-ListChats">
                    {chats
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((chat, index) => (
                        <Button
                          fullWidth
                          variant="text"
                          onClick={() => {
                            handleChangeChat(chat.id);
                            setShowMobileChats(false);
                          }}
                          sx={{ justifyContent: "flex-start" }}
                          style={{
                            borderLeft:
                              currentChatId === chat.id
                                ? "2px solid #1976d2"
                                : "none",
                            borderRadius: "0px",
                          }}
                        >
                          {chat.content.length > 18
                            ? chat.content.slice(0, 21) + "..."
                            : chat.content}
                        </Button>
                      ))}
                  </div>
                </Stack>
              </Box>
            </div>
          )}
          <div className="scrollbar-ListMessages">
            {messages.length === 0 ? (
              <div
                className={isMobile ? "divToStartChatMobile" : "divToStartChat"}
              >
                <RiOpenaiFill size="5em" />
                <p>Comece um chat</p>
              </div>
            ) : (
              messages.map((message, index) => (
                <div
                  key={index}
                  style={{
                    background: message.role !== "user" && "whitesmoke",
                    padding: "0.5rem",
                  }}
                >
                  <Chip
                    sx={{ marginBottom: "0.5rem" }}
                    icon={
                      message.role === "user" ? (
                        <RiUser3Line />
                      ) : (
                        <RiOpenaiFill />
                      )
                    }
                    label={
                      message.role === "user"
                        ? userSettings.username
                        : "Unik Assistant"
                    }
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    component="div"
                  >
                    <Box
                      display="block"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="right"
                      alignItems="center"
                    >
                      {message.role !== "user" && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleButtonCopyClick(message.content)}
                        >
                          <FaRegCopy fontSize="inherit" />
                        </IconButton>
                      )}
                    </Box>
                  </Typography>
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
            {isTyping && <p>Bot is typing...</p>}
          </div>
        </div>
        <FormControl
          sx={{ m: 1, paddingRight: "15px" }}
          fullWidth
          variant="outlined"
        >
          <OutlinedInput
            sx={{ borderRadius: "25px" }}
            id="outlined-adornment-password"
            placeholder="Escreve as tuas perguntas aqui..."
            disabled={isTyping}
            type={"text"}
            value={inputValue}
            multiline={true}
            minRows={1}
            maxRows={3}
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                handleButtonClick();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleButtonClick}
                  edge="end"
                >
                  {<BiSolidSend />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </div>
    </div>
  );
}

export default Chatbot;
