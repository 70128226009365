import React, { useEffect, useState } from "react";
import { findDocument } from "~/store/ducks/iflow/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PreviewComponent = ({ type, file }) => {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const dispatch = useDispatch();
  const fileExtension = file?.filename?.split(".").pop();
  const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
  const { currentDocument } = useSelector((state) => state.iflowReducer);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const match = /docid=(\d+)/.exec(file.link_url);
    const id = match ? match[1] : null;
    if (id && !currentDocument) {
      dispatch(findDocument(id));
    }
  }, [file, currentDocument, dispatch]);

  const generatePdfView = () => {
    const base64String = atob(currentDocument.datadoc);
    const bytes = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
      bytes[i] = base64String.charCodeAt(i);
    }
    const pdfBlob = new Blob([bytes.buffer], { type: "application/pdf" });
    return URL.createObjectURL(pdfBlob);
  };

  const generateImageView = () =>
    currentDocument ? `data:image/jpg;base64,${currentDocument.datadoc}` : "";

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const getPreviewContent = () => {
    if (isImage) {
      return (
        currentDocument && (
          <img
            src={generateImageView()}
            alt={currentDocument.filename}
            style={{ maxWidth: "100%", marginBottom: "10px", maxHeight: "600px" }}
          />
        )
      );
    } else {
      return (
        currentDocument && (
          isMobile ? (
            <Document file={generatePdfView()} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth}
                  style={{ width: "-webkit-fill-available", height: "500px" }}
                />
              ))}
            </Document>
          ) : (
            <iframe
              id="pdf-document"
              title="pdf-document"
              src={generatePdfView()}
              width="100%"
              height="500px"
            />
          )
        )
      );
    }
  };

  return <>{getPreviewContent()}</>;
};

export default PreviewComponent;