import * as actions from "../actions";

const initialState = {
  users: [],
  isLoadingUsers: false,
  legacyApps: [],
  legacyUsers: [],
  unikUser: undefined,
  isLoadingLegacyUsers: false,
  isLoadingLegacyApplications: false,
  isLoadingUnikUser: false,
  isPersisting: false,
  roles: [],
  successDeleteUser: false,
  successAddUser: false,
  isLoadingPullLegacyUsers: false,
  totalPulledLegacyUsers: undefined,
  extendedMiddlewareAttributes: undefined,
  askedForCheckOut: "",
  isLoadingAssumedCheckOut: false,
  askedForCheckIn: "",
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_LEGACY_APPS:
      return {
        ...state,
        isLoadingLegacyApplications: true,
      };
    case actions.SET_LEGACY_APPS:
      return {
        ...state,
        legacyApps: action.data,
        isLoadingLegacyApplications: false,
      };
    case actions.LOAD_LEGACY_USERS:
      return { ...state, isLoadingLegacyUsers: true };
    case actions.SET_LEGACY_USERS:
      return {
        ...state,
        legacyUsers: action.data,
        isLoadingLegacyUsers: false,
      };
    case actions.ADD_USER:
      return { ...state, isPersisting: true };
    case actions.SUCCESS_ADD_USER:
      return { ...state, isPersisting: false, successAddUser: true };
    case actions.FAIL_ADD_USER:
      return { ...state, isPersisting: false };
    case actions.UPDATE_USER:
      return { ...state, isPersisting: true };
    case actions.FAIL_UPDATE_USER:
      return { ...state, isPersisting: false };
    case actions.ASSUME_CHECKOUT:
      return { ...state, isLoadingAssumedCheckOut: true };
    case actions.ASSUME_CHECKIN:
      return { ...state, isLoadingAssumedCheckOut: true };
    case actions.UPDATE_ACCESS_CHECKOUT:
      return { ...state };
    case actions.SET_ASSUMED_CHECKOUT:
      return {
        ...state,
        askedForCheckOut: action.data,
        isLoadingAssumedCheckOut: false,
      };
    case actions.SET_ASSUMED_CHECKIN:
      return {
        ...state,
        askedForCheckIn: action.data,
        isLoadingAssumedCheckOut: false,
      };
    case actions.SUCCESS_UPDATE_USER:
      return { ...state, isPersisting: false };
    case actions.SUCCESS_DELETE_USER:
      return { ...state, successDeleteUser: true };
    case actions.REQUEST_USERS:
      return { ...state, isLoadingUsers: true };
    case actions.SUCCESS_REQUEST_USERS:
      return {
        ...state,
        users: action.data,
        isLoadingUsers: false,
      };
    case actions.CLEAR_USERS:
      return { ...state, users: initialState.users };
    case actions.SET_UNIK_USER:
      return {
        ...state,
        unikUser: action.data,
        isLoadingUnikUser: false,
      };
    case actions.CLEAR_UNIK_USER:
      return { ...state, unikUser: initialState.unikUser };
    case actions.SET_ROLES:
      return { ...state, roles: action.roles };
    case actions.LOAD_UNIK_USER_BY_ID:
      return { ...state, isLoadingUnikUser: true };
    case actions.CLEAR_DELETE_USER:
      return { ...state, successDeleteUser: false };
    case actions.CLEAR_ADD_USER:
      return { ...state, successAddUser: false };
    case actions.PULL_LEGACY_USERS:
      return {
        ...state,
        isLoadingPullLegacyUsers: true,
        totalPulledLegacyUsers: undefined,
      };
    case actions.PULL_LEGACY_USERS_ONLY_APP:
      return {
        ...state,
        isLoadingPullLegacyUsers: true,
        totalPulledLegacyUsers: undefined,
      };
    case actions.SET_PULLED_LEGACY_USERS:
      return {
        ...state,
        isLoadingPullLegacyUsers: false,
        totalPulledLegacyUsers: action.pulledLegacyUser,
      };
    case actions.CLEAR_PULLED_LEGACY_USERS:
      return {
        ...state,
        totalPulledLegacyUsers: initialState.totalPulledLegacyUsers,
      };
    case actions.REQUEST_EXTENDED_ATTRIBUTES:
      return { ...state };
    case actions.SUCESS_EXTENDED_ATTRIBUTES:
      return {
        ...state,
        extendedMiddlewareAttributes: action.data,
      };
    case actions.CLEAR_EXTENDED_ATTRIBUTES:
      return { ...state };
    default:
      return state;
  }
};
