import {
  GET_GRAPH_INFO,
  SET_GRAPH_INFO,
  GET_GRAPH_ALL_OPERATIONS,
  GET_GRAPH_ALL_GROUPS,
  SET_GRAPH_OPERATIONS,
  SET_GRAPH_GROUPS,
  GET_GRAPH_SPECIFIC_GROUP,
  GET_GRAPH_SPECIFIC_OPERATION,
  GET_GRAPH_INFO_WITH_DATES,
} from "./actionTypes";

const initialState = {
  isLoadingGraphInfo: false,
  graphInfo: {},
  graphGroups: [],
  graphOperations: [],
};

export function graphsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GRAPH_INFO:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case GET_GRAPH_INFO_WITH_DATES:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case SET_GRAPH_INFO:
      return {
        ...state,
        isLoadingGraphInfo: false,
        graphInfo: action.graphInfo,
      };
    case GET_GRAPH_ALL_OPERATIONS:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case SET_GRAPH_OPERATIONS:
      return {
        ...state,
        isLoadingGraphInfo: false,
        graphOperations: action.graphOperations,
      };
    case GET_GRAPH_ALL_GROUPS:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case SET_GRAPH_GROUPS:
      return {
        ...state,
        isLoadingGraphInfo: false,
        graphGroups: action.graphGroups,
      };
    case GET_GRAPH_SPECIFIC_GROUP:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case GET_GRAPH_SPECIFIC_OPERATION:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    default:
      return state;
  }
}
