import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import {
  PORTALRH_MIDDLEWARE,
  RH_MIDDLEWARE,
  IFLOW_APP,
} from "~/utils/constants";

import {
  setGraphInfo,
  setGraphOperations,
  setGraphGroups,
} from "./actionTypes";

import { activeError } from "~/components/Messages/actions";

export function* getGraphAllOperations() {
  try {
    const resp = yield http.get("api/operation/all", PORTALRH_MIDDLEWARE);
    yield put(setGraphOperations(resp.data));
  } catch (e) {
    yield put(setGraphOperations([]));
    yield put(activeError(e));
  }
}
export function* getGraphAllGroups() {
  try {
    const resp = yield http.get("api/get-all-groups", PORTALRH_MIDDLEWARE);
    yield put(setGraphGroups(resp.data));
  } catch (e) {
    yield put(setGraphGroups([]));
    yield put(activeError(e));
  }
}

export function* getGraphSpecificGroup({ id }) {
  try {
    const resp = yield http.get(
      `api/get-all-groups?opeId=${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setGraphGroups(resp.data));
  } catch (e) {
    yield put(setGraphGroups([]));
    yield put(activeError(e));
  }
}
export function* getGraphSpecificOperation({ id }) {
  try {
    const resp = yield http.get(
      `api/operation/get-operations-by-group-id?groupId=${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setGraphOperations(resp.data));
  } catch (e) {
    yield put(setGraphOperations([]));
    yield put(activeError(e));
  }
}

const createGraphConfig = (labels, data) => {
  const colors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(199, 199, 199, 0.2)",
    "rgba(83, 102, 255, 0.2)",
    "rgba(255, 99, 71, 0.2)",
    "rgba(60, 179, 113, 0.2)",
    "rgba(255, 140, 0, 0.2)",
    "rgba(106, 90, 205, 0.2)",
  ];

  const borderColors = colors.map((color) => color.replace("0.2", "1"));

  return {
    type: "bar",
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          borderColor: borderColors,
          borderWidth: 1,
          barThickness: 20,
        },
      ],
    },
    options: {
      scales: {
        y: { beginAtZero: true },
      },
      plugins: {
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: (value) => value,
          font: { weight: "bold" },
        },
        title: { display: false },
      },
      legend: { display: false },
      tooltips: {
        backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
        titleMarginBottom: 10,
        titleFontColor: "#6e707e",
        titleFontSize: 14,
        borderColor: "#dddfeb",
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: false,
        intersect: false,
        mode: "index",
        caretPadding: 10,
        callbacks: {
          label: (tooltipItem, chart) => {
            const datasetLabel =
              chart.datasets[tooltipItem.datasetIndex].label || "";
            return `${datasetLabel} ${tooltipItem.yLabel}`;
          },
        },
      },
    },
  };
};

function* fetchGraphData(url) {
  try {
    const response = yield http.get(url, PORTALRH_MIDDLEWARE);
    const resp1 = response.data;
    const labels = Object.keys(resp1);
    const data = Object.values(resp1);
    const graphConfig = createGraphConfig(labels, data);
    yield put(setGraphInfo(graphConfig));
  } catch (e) {
    yield put(setGraphInfo({}));
    yield put(activeError(e));
  }
}

export function* getGraphInfoWithDate({ payload }) {
  const { operation, group, startAt, endAt, urlRequest } = payload;
  const params = new URLSearchParams();

  if (operation) params.append("opeId", operation);
  if (group) params.append("groupId", group);
  if (startAt) params.append("dataInicio", startAt);
  if (endAt) params.append("dataFim", endAt);

  const url = `api/request/${urlRequest}?${params.toString()}`;

  yield fetchGraphData(url);
}

export function* getGraphInfo({ payload }) {
  const { operation, group } = payload;
  const url = `api/request/get-registed-requests?opeId=${operation}&groupId=${group}`;
  yield fetchGraphData(url);
}
