import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProcessosIframe from "~/components/ProcessosIframe";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import { setProcessFormMenuItemClicked } from "~/containers/Layout/actions";
import NativeProcessesWrapper from "./NativeProcessesWrapper";
import oauth from "~/utils/oauth";
import {
  getOrganizations,
  clearOrganizations,
} from "~/store/ducks/organizations/actionTypes";
import AlertComponentWrapper from "./AlertComponentWrapper";
import { hasError, shouldUseNativeWrapper, parseJsonFromString } from "~/utils";

const devLogConsole = require("~/utils/devLog");
const ProcessosFormHandler = (props) => {
  const { app, className, general } = props;
  const dispatch = useDispatch();
  const { currentFormUrl, currentFormMenuItemClicked } = useSelector(
    (state) => state.globalReducer
  );
  const { formdata } = useSelector((state) => state.processesReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { organizations } = useSelector((state) => state.organizationsReducer);

  useEffect(() => {
    dispatch(getOrganizations());
    return () => {
      dispatch(clearOrganizations());
    };
  }, [dispatch]);

  const renderProcesses = () => {
    const data = JSON.parse(localStorage.getItem("dataToStore")) || {};
    const openNewTabMenuLocal =
      JSON.parse(localStorage.getItem("openNewTabMenu")) || false;
    const dataToStoreTemp = {
      shouldRenderProcesses: true,
      currentFormUrl: currentFormUrl,
      className: className,
      formdata: formdata,
      windowLocation: window.location.href,
    };
    devLogConsole(dataToStoreTemp, "dataToStoreTemp");
    const shouldOpenInNewTab =
      organizations[0]?.openNewTab || openNewTabMenuLocal === true;
    const isSameLocation = data?.windowLocation === window.location.href;
    const hasFormData =
      dataToStoreTemp.currentFormUrl !== "" &&
      dataToStoreTemp.formdata !== null;

    if (hasFormData) {
      const isJson = shouldUseNativeWrapper(dataToStoreTemp.formdata);
      let formFromProcess = null;

      if (isJson && typeof dataToStoreTemp.formdata === "string") {
        formFromProcess = parseJsonFromString(dataToStoreTemp.formdata);
      } else if (typeof dataToStoreTemp.formdata === "object") {
        formFromProcess = dataToStoreTemp.formdata;
      }

      if (shouldOpenInNewTab) {
        localStorage.setItem("dataToStore", JSON.stringify(dataToStoreTemp));
        window.open(dataToStoreTemp.windowLocation, "_blank");
      } else {
        if (hasError(dataToStoreTemp.formdata)) {
          return <AlertComponentWrapper />;
        }
        const Component = isJson ? NativeProcessesWrapper : ProcessosIframe;
        return (
          <Component
            className={className}
            formFromProcess={isJson ? formFromProcess : undefined}
            urlFormData={!isJson ? dataToStoreTemp.currentFormUrl : undefined}
          />
        );
      }
    }
    if (
      (data !== null && !shouldOpenInNewTab && isSameLocation) ||
      (data !== null && openNewTabMenuLocal && isSameLocation)
    ) {
      if (data.formdata !== null) {
        const isJson = shouldUseNativeWrapper(data.formdata);
        let formFromProcess = null;

        if (isJson && typeof data.formdata === "string") {
          formFromProcess = parseJsonFromString(data.formdata);
        } else if (typeof data.formdata === "object") {
          formFromProcess = data.formdata;
        }

        if (hasError(data.formdata)) {
          return <AlertComponentWrapper />;
        }
        const Component = isJson ? NativeProcessesWrapper : ProcessosIframe;
        return (
          <Component
            className={className}
            urlFormData={!isJson ? data.currentFormUrl : undefined}
            formFromProcess={isJson ? formFromProcess : undefined}
          />
        );
      }
    }
  };

  useEffect(() => {
    const dataToStore = JSON.parse(localStorage.getItem("dataToStore"));
    let url = "";
    devLogConsole(
      currentFormUrl,
      "currentFormUrl--------AQUI*****************-"
    );
    if (menu && currentFormUrl !== "" && currentFormMenuItemClicked === true) {
      const menuItem = menu.find((item) => item.route === `${app}/processos`);
      if (menuItem && currentFormUrl) {
        url = currentFormUrl;
        dispatch(setProcessFormMenuItemClicked(false));
      }
      if (general) {
        url = "inicio_flow.jsp?flowid=" + currentFormUrl;
      }
    } else if (currentFormUrl === "" && dataToStore != null) {
      url = dataToStore.currentFormUrl;
    }

    if (url) {
      const payload = {
        url: url + "&Authorization=" + oauth.getAccessToken(),
      };
      const isNewForm = true;
      dispatch(getIflowFormdata(payload, isNewForm));
    }

    return () => {
      localStorage.removeItem("iflowAccordion");
    };
  }, [currentFormUrl, currentFormMenuItemClicked]);

  return <>{renderProcesses()}</>;
};
export default ProcessosFormHandler;
