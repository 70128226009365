import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Header from "~/containers/Layout/components/Header";
import Puzzle from "~/components/Puzzle";

import IframeHandler from "~/components/IframeHandler";
import Admin from "../../pages/Admin";
import AboutComponent from "~/components/AboutComponent";
import Account from "~/pages/Account";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "~/assets/css/styles.css";
import { clearPuzzleItems } from "~/pages/Admin/SystemSettings/actions";
import { clearCheckToken } from "~/store/ducks/oauth/actionTypes";
import { setMenuApplication } from "~/store/ducks/applicationMenu/actionTypes";
import OpenAI from "~/components/OpenAI";
import Prompts from "~/components/Prompts";
import { requestUser, clearUser } from "./actions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  assumeCheckIn,
  updateAccessCheckout,
} from "~/pages/Admin/User/actions";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import {
  updateUserSettings,
  clearUserSettings,
  getIsFirstLogin,
  postIsFirstLogin,
} from "../../pages/User/actions";
import {
  getPuzzleItems,
  requestConfiguration,
} from "../../pages/Admin/SystemSettings/actions";
import { findUDWSettings } from "~/store/ducks/udw/actionTypes";

import i18n from "~/i18n";
import IdleTimer from "react-idle-timer";
import oauth from "~/utils/oauth";
import { logout } from "~/pages/Login/actions";
import { isEmpty } from "lodash";

import Spinner from "~/components/Spinner";
import Chat from "~/components/chat";

import chatBot from "~/assets/img/chat/Chatbot.jpg";
import darkchatBot from "~/assets/img/chat/Chatbot2.jpg";
import finAssistant from "~/assets/img/chat/finAssistant.png";
import hrAssistant from "~/assets/img/chat/hrAssistant.png";
import bpmAssistant from "~/assets/img/chat/bpmAssistant.png";
import complianceAssistant from "~/assets/img/chat/complianceAssistant.png";
import gaiAssistant from "~/assets/img/chat/gaiAssistant.png";

import Uniksystem from "~/containers/BaseApplication/Uniksystem";

import GDPR from "~/containers/BaseApplication/GDPR";
import Compliance from "~/containers/BaseApplication/Compliance";
import Error404 from "~/components/Exception/Error404";

import GlobalSolver from "~/containers/BaseApplication/GlobalSolver";
import DataCapture from "../../pages/DataCapture";
import ADataCapture from "~/pages/ADataCapture";
import ProcessValidation from "../../pages/ProcessValidation";
import Financeira from "~/pages/Financeira";
import GAI from "../../pages/GAI";
import RH from "../../pages/RH";
import PortalRH from "../../pages/PortalRH";
import Dashboard from "../../pages/Dashboards";
import BPM from "../../pages/BPM";
import Onboarding from "~/pages/Onboarding";
import Easypay from "~/pages/Easypay";
import EasypayComponents from "~/pages/Easypay/pages/AllComponents";
import LiftWorldComponents from "~/pages/Liftworld/pages/AllComponents";
import EasypayBackOffice from "~/pages/Easypay/pages/BackofficeUser/EasypayBackOfficePage";
import BackOfficeEasypay from "~/pages/Easypay/pages/BackofficeOrganization";
import BackOfficeEasypayLeadsPage from "~/pages/Easypay/pages/BackofficeOrganization/Leads/index.js";
import BackOfficeEasypayOnbardingPage from "~/pages/Easypay/pages/BackofficeOrganization/Onboarding";
import BackOfficePreviewDocumentPage from "~/pages/Easypay/pages/BackofficeOrganization/RequestNewDoc/BackOfficePreviewDocumentPage";
import BackOfficeRequestDocumentPage from "~/pages/Easypay/pages/BackofficeOrganization/RequestNewDoc/BackOfficeRequestDocumentPage";
import WorkPlanPage from "~/pages/Liftworld/pages/WorkPlanPage";
import Budgetmgr from "~/pages/Budgetmgr";
import Tools from "~/components/ToolsUnik/index";
import { Modal, Col, Form, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { GLOBAL_SOLVER_MIDDLEWARE } from "~/utils/constants";
import Anacom from "~/pages/Anacom";
import { Stack } from "@mui/system";
import Dropdown from "~/components/Dropdown";

const Layout = (props) => {
  const { keycloak, t } = props;
  const { path } = useRouteMatch();

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const isProductionMode = configuration ? configuration.productionMode : true;

  const dispatch = useDispatch();
  let location = useLocation();

  const { user, isLoadingUser } = useSelector((state) => state.globalReducer);
  const { authKeycloakConfig } = useSelector((state) => state.loginReducer);
  const {
    userSettings,
    isLoadingUserSettings,
    isfirstlogin,
    post_isfirstlogin,
  } = useSelector((state) => state.userSettingsReducer);
  const { udwSettings } = useSelector((state) => state.udwReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [showChat, setShowChat] = useState(false);
  const [styleSheet, setFormStyleSheet] = useState("");
  const [translations, setTranslations] = useState("");

  const [showModalFirstLogin, setshowModalFirstLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [keyclocksettings, setkeyclocksettings] = useState(undefined);
  const [isMongoOn, setIsMongoOn] = useState(undefined);
  const [checkoutHour, setCheckoutHour] = useState("now");
  const [cleanDropdown, setCleanDropdown] = useState(false);

  const [openAnchor, setOpenAnchor] = useState(true);
  const [openAnchorMaintenance, setOpenAnchorMaintenance] = useState(false);
  const verticalAnchor = "bottom";
  const horizontalAnchor = "right";

  const listHoursCheckOut = [
    { label: "Agora", value: "now", selected: false },
    { label: "5 min", value: "5", selected: false },
    { label: "15 min", value: "15", selected: false },
    { label: "30 min", value: "30", selected: false },
    { label: "1 H", value: "1", selected: false },
  ];

  //console.log("CHECKOUT------------", user, userSettings);

  useEffect(() => {
    const hasAccessToken = oauth.getAccessToken();
    const { checkoutId, checkedOut } = user;

    setOpenAnchor(checkoutId && !checkedOut && hasAccessToken);
    setOpenAnchorMaintenance(checkoutId && checkedOut && hasAccessToken);
  }, [user]);

  const handleConfirmCheckOut = () => {
    if (checkoutHour === "now") {
      setOpenAnchor(false);
      oauth.clearAppStorage();
      handleLogout();
    } else {
      const payload = {
        onBehalfOf: user?.id,
        hourToAccess: checkoutHour,
      };
      dispatch(updateAccessCheckout(payload));
      setOpenAnchor(false);
    }
  };

  const handleConfirmCheckOutMaintenance = async () => {
    const payload = {
      userId: user.id,
    };
    await dispatch(assumeCheckIn(payload));
    handleLogout();
  };

  const handleChangeCheckOut = (event) => {
    setCheckoutHour(event);
  };

  const getUserTheme = () => {
    let config = configuration?.loginPage;
    if (config === "nblp") {
      return "nblp";
    }
    return userSettings.theme ? userSettings.theme : "default";
  };
  useEffect(() => {
    setkeyclocksettings(fetchData());
  }, []);

  useEffect(() => {
    dispatch(requestConfiguration());
    dispatch(findUDWSettings());
    dispatch(requestUser());
    dispatch(getIsFirstLogin());
    document.getElementsByTagName("body")[0].className = getUserTheme();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingUser || !isLoadingUserSettings) {
      if (isfirstlogin == true) setshowModalFirstLogin(isfirstlogin);
    }
  }, [isfirstlogin, post_isfirstlogin]);

  useEffect(() => {
    if (user.username && oauth.getAccessToken()) {
      //dispatch(findUserSettings(user.username));
      //socket.emit('userIsOnline', user);
    }
    dispatch(getPuzzleItems());
  }, [dispatch, user]);

  useEffect(() => {
    if (!isLoadingUser || !isLoadingUserSettings) {
      if (isfirstlogin == true) setshowModalFirstLogin(isfirstlogin);
    }
  }, [isfirstlogin, post_isfirstlogin]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = getUserTheme();
    if (userSettings.language)
      if (
        window.localStorage.getItem("lng") !==
        userSettings.language.toLowerCase()
      ) {
        window.localStorage.setItem("lng", userSettings.language.toLowerCase());
        i18n.changeLanguage(userSettings.language.trim().toLowerCase());
      }
  }, [userSettings]);

  useEffect(() => {
    if (udwSettings && udwSettings.mongoHost) {
      /*TODO change for boolean */
      if (udwSettings.mongoHost === "MongoDB not configured")
        setIsMongoOn(false);
      else setIsMongoOn(true);
    }
  }, [udwSettings]);

  useEffect(() => {
    if (configuration && configuration.browserTabName) {
      document.title = configuration.browserTabName || "";
    }
  }, [configuration]);

  const saveHistory = () => {
    if (!isEmpty(userSettings)) {
      if (location.pathname === undefined) return;
      if (location.state === undefined) return;
      if (location.state && location.state.name === "Uniksystem") return;
      if (
        userSettings.history &&
        userSettings.history.find((item) => item.pathname === location.pathname)
      )
        return;
      let newHistory;
      newHistory = Array.from(userSettings.history ? userSettings.history : []);
      if (newHistory.length >= 10) {
        newHistory.splice(0, 1);
        newHistory.push(location);
      } else {
        newHistory.push(location);
      }
      const payload = userSettings;
      payload.history = newHistory;
      dispatch(updateUserSettings(payload));
    }
  };

  const ChatBot = ({ location }) => {
    const [showA, setShowA] = useState(false);

    const toggleShowA = () => setShowA(!showA);
    let url;
    if (location && location.pathname === "/uniksystem/hr") url = hrAssistant;
    else if (location && location.pathname === "/uniksystem/bpm")
      url = bpmAssistant;
    else if (
      location &&
      (location.pathname === "/uniksystem/compliance" ||
        location.pathname === "/uniksystem/gdpr")
    )
      url = complianceAssistant;
    else if (location && location.pathname === "/uniksystem/gai")
      url = gaiAssistant;
    else url = finAssistant;

    return (
      <>
        {showA && (
          <div className="chat-panel">
            <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "5px",
                width: "400px",
                zIndex: "9999",
              }}
            >
              <img src={url} width="400px" alt="" />
            </div>
          </div>
        )}
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="tooltipChatBot">Precisa de ajuda?</Tooltip>}
        >
          <img
            className="chat-bot img-fluid float-right"
            src={getUserTheme() === "dark" ? darkchatBot : chatBot}
            alt="chatBot"
            onClick={toggleShowA}
          />
        </OverlayTrigger>
      </>
    );
  };

  useEffect(() => {
    saveHistory();
  }, [location]);

  function handleChangeTheme(theme) {
    const payload = userSettings;
    payload.theme = theme;
    dispatch(updateUserSettings(payload));
  }

  function handleChangeLanguage(language) {
    const payload = userSettings;
    payload.language = language;
    dispatch(updateUserSettings(payload));
  }

  const TIMEOUT = 604800 * 1000;
  const _history = useHistory();

  const onIdle = (_) => {
    handleLogout();
  };

  useEffect(() => {
    /*
    if (showChat) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }
    */
  }, [showChat]);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  /*Get extraconfiguration*/
  useEffect(() => {
    if (menu) {
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/");
        const app = arr3[0];

        const menuItem = menu.find(
          (item) =>
            item.route === `${app}/processos` ||
            item.route === `${app}/processes`
        );
        if (menuItem) {
          const extraConfiguration_ = JSON.parse(
            menuItem.extraConfiguration || false
          );
          const styleSheet_ =
            extraConfiguration_ && extraConfiguration_.styleSheet;

          let translations_ =
            extraConfiguration_ && extraConfiguration_?.translations;
          if (translations_ === undefined && styleSheet_ !== undefined) {
            translations_ = extraConfiguration_?.styleSheet.translations;
          }
          setFormStyleSheet(styleSheet_);
          setTranslations(translations_);
        }
      }
    }
  }, [menu]);

  function handleLogout() {
    [
      logout(user.username),
      clearCheckToken(),
      clearUserSettings(),
      clearUser(),
      setMenuApplication([]),
      clearPuzzleItems(),
    ].forEach((action) => dispatch(action));

    oauth.clearAppStorage();

    const payload = {
      userId: user.id,
    };
    dispatch(assumeCheckIn(payload));

    const contextElement = document.getElementById(
      GLOBAL_SOLVER_MIDDLEWARE.context
    );
    if (contextElement) {
      contextElement.src = GLOBAL_SOLVER_MIDDLEWARE.iframe;
    }

    if (keyclocksettings) {
      const currentUrl = window.location.href;
      const match = currentUrl.match(/^(https?:\/\/[^/]+)/);
      if (match && authKeycloakConfig.platform) {
        const redirectUrl = match[1];
        const redirect_uri = encodeURIComponent(redirectUrl);
        const logoutUrl = `${keyclocksettings.keycloakData.url}/realms/${keyclocksettings.keycloakData.realm}/protocol/openid-connect/logout?redirect_uri=${redirect_uri}`;
        window.location.href = logoutUrl;
      }
    } else {
      _history.push("/");
    }

    oauth.logout();
    window.location.reload();
  }

  const fetchData = async () => {
    const SERVER_URL = window.location.origin;
    try {
      const response = await fetch(
        SERVER_URL + "/uniksystem/api/open/keycloak-settings"
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching keycloak data:", error);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
  };

  const isMobile = window.innerWidth < 992 ? true : false;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  const handleSendNewPassword = (e) => {
    e.preventDefault();
    if (password == "" || confirmPassword == "") {
      setError(t("layout.notEmpty"));
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (password === confirmPassword) {
      saveNewPassword();
    } else {
      setError(t("layout.equals"));
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
  };

  function saveNewPassword() {
    const payload = {
      username: user?.username,
      newPassword: password,
    };
    dispatch(postIsFirstLogin(payload));
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const renderFirstLoginBody = () => {
    return (
      <>
        <div className="d-flex justify-content-between">
          {/* New Password */}
          <Col sm="6" md="6" lg="6">
            <Form.Group controlId="validationCustomPassword">
              <Form.Label>
                {t("layout.newPassword")} {asterisk}
              </Form.Label>
              <div style={{ display: "inline-flex" }}>
                <Form.Control
                  required
                  disabled={false}
                  type={showPassword ? "text" : "password"}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                  value={password}
                  autoComplete="new-password"
                />
                <button
                  onClick={togglePasswordVisibility}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </Form.Group>
          </Col>
          <Col sm="6" md="6" lg="6">
            <Form.Group controlId="validationConfirmPassword">
              <Form.Label>
                {t("layout.confirmNewPassword")} {asterisk}
              </Form.Label>
              <div style={{ display: "inline-flex" }}>
                <Form.Control
                  required
                  disabled={false}
                  type={showConfirmPassword ? "text" : "password"}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError("");
                  }}
                  value={confirmPassword}
                  autoComplete="new-password"
                />
                <button
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </Form.Group>
          </Col>
        </div>
        <div
          style={{
            float: "right",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {error && (
            <Form.Text style={{ color: "#dc3545", paddingRight: "20px" }}>
              {error}
            </Form.Text>
          )}
          <button
            className="card-button btn btn-primary mt-auto"
            onClick={(e) => {
              handleSendNewPassword(e);
            }}
          >
            {t("layout.save")}
          </button>
        </div>
      </>
    );
  };

  const renderPostFirstLoginBody = () => {
    const labelColor = post_isfirstlogin ? "#006400" : "#dc3545";
    const labelText = post_isfirstlogin
      ? t("layout.isfirstLoginTrue")
      : t("layout.isfirstLoginFalse");

    return (
      <>
        <div className="d-flex justify-content-between">
          <Form.Group controlId="validationCustomPassword">
            <Form.Label style={{ color: labelColor, fontWeight: "600" }}>
              {labelText}
            </Form.Label>
          </Form.Group>
        </div>
        <div style={{ float: "right", marginTop: "20px" }}>
          <button
            className="card-button btn btn-primary mt-auto"
            onClick={() => handleLogout()}
          >
            {t("layout.close")}
          </button>
        </div>
      </>
    );
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      oauth.clearAppStorage();
      return handleLogout();
    }
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const renderTimeMaintenance = ({ remainingTime }) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0) {
      const payload = {
        userId: user.id,
      };
      dispatch(assumeCheckIn(payload));
      return handleLogout();
    }
    return (
      <div style={{ fontSize: "small" }}>
        {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </div>
    );
  };

  return (
    <>
      <Spinner
        spinning={isLoadingUser || isLoadingUserSettings}
        wrapper={true}
      />

      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={TIMEOUT}
      />

      {!location.pathname.includes("uniksystem/easypay") &&
        !location.pathname.includes("uniksystem/onboarding/user") &&
        !location.pathname.includes("uniksystem/onboarding/backoffic-user") &&
        !location.pathname.includes(
          "uniksystem/onboarding/backoffic-organization"
        ) && (
          <Header
            keycloak={keycloak}
            theme={getUserTheme()}
            handleChangeTheme={handleChangeTheme}
            handleChangeLanguage={handleChangeLanguage}
            toggleChat={() => setShowChat(!showChat)}
            styleSheet={styleSheet}
          />
        )}

      <Chat showChat={showChat} />

      <GlobalSolver />
      <Modal
        show={showModalFirstLogin}
        dialogClassName="custom-modal"
        centered
        backdrop="static"
      >
        {post_isfirstlogin != null ? (
          ""
        ) : (
          <Modal.Header>
            <Modal.Title>{t("layout.changePassword")}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          {post_isfirstlogin != null
            ? renderPostFirstLoginBody()
            : renderFirstLoginBody()}
        </Modal.Body>
      </Modal>
      {!showModalFirstLogin && (
        <div
          className="wrapper"
          onDrop={(e) => handleDrag(e)}
          onDragOver={(e) => handleDrag(e)}
        >
          <DndProvider backend={HTML5Backend}>
            <div id="appwrapper" style={{ display: "flex" }}>
              <Switch>
                <Route path={`${path}/bpm`}>
                  <BPM isMongoOn={isMongoOn} styleSheet={styleSheet} />
                </Route>

                <Route
                  path={`${path}/${GLOBAL_SOLVER_MIDDLEWARE.context}`}
                  render={() => (
                    <IframeHandler name={GLOBAL_SOLVER_MIDDLEWARE.context} />
                  )}
                />

                <Route path={`${path}/rh`}>
                  <RH isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/portalrh`}>
                  <PortalRH
                    isMongoOn={isMongoOn}
                    styleSheet={styleSheet}
                    translations={translations}
                  />
                </Route>
                <Route path={`${path}/prompts`}>
                  <Prompts
                    isMongoOn={isMongoOn}
                    styleSheet={styleSheet}
                    translations={translations}
                  />
                </Route>
                <Route path={`${path}/openai`}>
                  <OpenAI
                    isMongoOn={isMongoOn}
                    styleSheet={styleSheet}
                    translations={translations}
                  />
                </Route>
                <Route path={`${path}/dashboard`}>
                  <Dashboard isMongoOn={isMongoOn} styleSheet={styleSheet} />
                </Route>

                <Route path={`${path}/gdpr`}>
                  <GDPR isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/compliance`}>
                  <Compliance isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/gai`}>
                  <GAI isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/financeira`}>
                  <Financeira isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/datacapture`}>
                  <DataCapture isMongoOn={isMongoOn} />
                </Route>
                <Route path={`${path}/adatacapture`}>
                  <ADataCapture isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/validprocs`}>
                  <ProcessValidation isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/onboarding`}>
                  <Onboarding isMongoOn={isMongoOn} />
                </Route>

                <Route exact path={`${path}/easypay`}>
                  <Easypay isMongoOn={isMongoOn} />
                </Route>
                <Route exact path={`${path}/easypay/componentes`}>
                  <EasypayComponents isMongoOn={isMongoOn} />
                </Route>
                <Route exact path={`${path}/liftworld/componentes`}>
                  <LiftWorldComponents isMongoOn={isMongoOn} />
                </Route>
                <Route exact path={`${path}/liftworld/workplan`}>
                  <WorkPlanPage isMongoOn={isMongoOn} />
                </Route>
                <Route path={`${path}/budgetmgr`}>
                  <Budgetmgr isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/claimsmgt`}>
                  <Anacom isMongoOn={isMongoOn} />
                </Route>
                <Route exact path={`${path}/easypay/backoffice`}>
                  <EasypayBackOffice isMongoOn={isMongoOn} />
                </Route>
                <Route exact path={`${path}/easypay/backoffice-leads`}>
                  <BackOfficeEasypay isMongoOn={isMongoOn} />
                </Route>
                <Route
                  exact
                  path={`${path}/easypay/backoffice-onboarding-page/:token`}
                >
                  <BackOfficeEasypayOnbardingPage isMongoOn={isMongoOn} />
                </Route>
                <Route
                  exact
                  path={`${path}/easypay/backoffice-leads-page/:token`}
                >
                  <BackOfficeEasypayLeadsPage isMongoOn={isMongoOn} />
                </Route>

                <Route
                  exact
                  path={`${path}/easypay/backoffice-document-preview-page/:id`}
                  component={BackOfficePreviewDocumentPage}
                />

                <Route
                  exact
                  path={`${path}/easypay/backoffice-request-document-page/:id`}
                >
                  <BackOfficeRequestDocumentPage isMongoOn={isMongoOn} />
                </Route>

                <Route path={`${path}/admin`}>
                  <Admin />
                </Route>
                <Route path={`${path}/about`}>
                  <AboutComponent />
                </Route>
                <Route exact path={`${path}/tools`}>
                  <Tools />
                </Route>
                <Route path={`${path}/account`}>
                  <Account />
                </Route>
                <Route exact path={`${path}`}>
                  <Uniksystem isMongoOn={isMongoOn} />
                </Route>
                <Route path={`${path}/*`}>
                  <Error404 />
                </Route>
              </Switch>
            </div>
          </DndProvider>
          {/* {!isProductionMode && <ChatBot location={location} />} */}
          {!isMobile && <Puzzle />}
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: verticalAnchor,
          horizontal: horizontalAnchor,
        }}
        open={openAnchor}
        autoHideDuration={null} // Impede que o Snackbar seja fechado automaticamente
        disableWindowBlurListener // Impede que o Snackbar feche ao clicar fora
        key={verticalAnchor + horizontalAnchor}
      >
        <Alert severity={"warning"} sx={{ width: "100%" }}>
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Pedido de Manutenção</strong>
            <div>
              <CountdownCircleTimer
                isPlaying
                duration={40}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[10, 6, 3, 0]}
                onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                size={40} // Ajuste o tamanho conforme necessário
                strokeWidth={4} // Ajuste a espessura conforme necessário
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </AlertTitle>
          Foi feito um pedido para colocar o utilizador em manutenção.
          <br />
          Se necessitar de algum tempo para terminar o que está a fazer, indique
          o tempo de adiamento.
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifySelf: "right", marginTop: "2rem" }}
          >
            <Dropdown
              style={{ borderColor: "red !important" }}
              placeholder={"Selecione o tempo de adiamento"}
              emptyDefault={true}
              list={listHoursCheckOut}
              handleSubmit={handleChangeCheckOut}
              openUp={true}
              flowId={checkoutHour}
              resetDropdown={cleanDropdown}
              setResetDropdown={setCleanDropdown}
              orderedAlphabetical={true}
            />
            <Button
              className="card-button-checkout"
              onClick={handleConfirmCheckOut}
            >
              Confirmar
            </Button>
          </Stack>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: verticalAnchor,
          horizontal: horizontalAnchor,
        }}
        open={openAnchorMaintenance}
        autoHideDuration={null} // Impede que o Snackbar seja fechado automaticamente
        disableWindowBlurListener // Impede que o Snackbar feche ao clicar fora
        key={verticalAnchor + horizontalAnchor}
      >
        <Alert
          severity={"info"}
          sx={{ width: "100%", "& .MuiAlert-message": { padding: "0px 3px" } }}
        >
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <strong style={{ fontSize: "smaller" }}>User em Manutenção</strong>
            <div>
              <CountdownCircleTimer
                isPlaying
                duration={3600} // Duration in seconds
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[3600, 3600 * 0.6, 3600 * 0.3, 0]} // Adjust colorsTime to match the duration
                onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                size={40} // Adjust size as needed
                strokeWidth={0} // Adjust stroke width as needed
              >
                {renderTimeMaintenance}
              </CountdownCircleTimer>
            </div>
          </AlertTitle>
          <Stack direction="row" spacing={2} sx={{ justifySelf: "right" }}>
            <Button
              style={{ fontSize: "small" }}
              className="card-button-checkout"
              onClick={handleConfirmCheckOutMaintenance}
            >
              Terminar
            </Button>
          </Stack>
        </Alert>
      </Snackbar>
    </>
  );
};
export default withNamespaces()(Layout);
