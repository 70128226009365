import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_GRAPH_INFO,
  GET_GRAPH_ALL_OPERATIONS,
  GET_GRAPH_ALL_GROUPS,
  GET_GRAPH_SPECIFIC_GROUP,
  GET_GRAPH_SPECIFIC_OPERATION,
  GET_GRAPH_INFO_WITH_DATES,
} from "../ducks/graphs/actionTypes";

import {
  getGraphInfo,
  getGraphAllOperations,
  getGraphAllGroups,
  getGraphSpecificGroup,
  getGraphSpecificOperation,
  getGraphInfoWithDate,
} from "../ducks/graphs/sagas";

export const graphsSagas = [
  takeLatest(GET_GRAPH_INFO, getGraphInfo),
  takeLatest(GET_GRAPH_ALL_OPERATIONS, getGraphAllOperations),
  takeLatest(GET_GRAPH_ALL_GROUPS, getGraphAllGroups),
  takeLatest(GET_GRAPH_SPECIFIC_GROUP, getGraphSpecificGroup),
  takeLatest(GET_GRAPH_SPECIFIC_OPERATION, getGraphSpecificOperation),
  takeLatest(GET_GRAPH_INFO_WITH_DATES, getGraphInfoWithDate),
];
