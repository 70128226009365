import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setGraphInfo } from "~/store/ducks/graphs/actionTypes";
import { withNamespaces } from "react-i18next";
import StatisticsOpenTasks from "./StatisticsOpenTasks";
import StatisticsPendingTasks from "./StatisticsPendingTasks";
import StatisticsConcluedTasks from "./StatisticsConcluedTasks";
import StatisticsMensalActiveColaborators from "./StatisticsMensalActiveColaborators";
import StatisticsMensalColaborators from "./StatisticsMensalColaborators";

const Statistics = ({ selectedItem = {}, t, translations, isMobile }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGraphInfo({}));
  }, []);

  const renderComponent = () => {
    const component = selectedItem[Object.keys(selectedItem)[0]].component;
    switch (component) {
      case "NPTRA":
        return <StatisticsOpenTasks />;
      case "NPTP":
        return <StatisticsPendingTasks />;
      case "NPTC":
        return <StatisticsConcluedTasks />;
      case "TCAM":
        return <StatisticsMensalActiveColaborators />;
      case "TCM":
        return <StatisticsMensalColaborators />;
      default:
        return null;
    }
  };

  return renderComponent();
};

export default withNamespaces()(Statistics);
