import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"

const StatisticsMensalActiveColaborators = () => {
  const dispatch = useDispatch();
  const refStatisticsMensalActiveColaborators = useRef(null);

  const { graphInfo } = useSelector((state) => state.graphsReducer);

  useEffect(() => {
    if (Object.keys(graphInfo).length > 0) {
      const myChartRef = refStatisticsMensalActiveColaborators.current.getContext("2d");

      // Add the ChartDataLabels plugin to the chart configuration
      const chartConfig = {
        ...graphInfo,
        plugins: [ChartDataLabels],
      };

      new Chart(myChartRef, chartConfig);
    }
  }, [graphInfo]);

  return (
    <Row>
      <Col
        xs={12}
        md={4}
        lg={4}
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "100%", width: "auto" }}>
          <canvas
            id="myAreaChart"
            ref={refStatisticsMensalActiveColaborators}
            width={650}
            height={280}
          ></canvas>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(StatisticsMensalActiveColaborators);
