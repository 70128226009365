import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import {
  getUserSynchronizationLog,
} from "~/store/ducks/portalrh/actionTypes";

const ErrorLogsTable = ({ t }) => {
  const { userSynchronizationLog, isLoadingUserSynchronizationLog } = useSelector(
    (state) => state.portalrhReducer
  );
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSynchronizationLog());
  }, []);

  useInterval(() => {
    dispatch(getUserSynchronizationLog());
  }, 1000 * 60 * 3);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: t("portalrh.errorLogs.id"),
      sort: true,
    },
    {
      dataField: "numeroFuncionario",
      text: t("portalrh.errorLogs.employeeNum"),
      sort: true,
    },
    {
      dataField: "lotNumber",
      text: t("portalrh.errorLogs.lotNumber"),
      sort: true,
    },
    {
      dataField: "data",
      text: t("portalrh.errorLogs.data"),
      sort: true,
    },
    {
      dataField: "razaoErro",
      text: t("portalrh.errorLogs.errorDesc"),
      sort: true,
    },
  ];

  const mobileColumns = [
    {
      dataField: "info",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const headers = formatExtraData.headers;
        return (
          <div>
            <br />
            {headers.map((header, index) => {
              const key = header.dataField;
              if (key && row[key] !== undefined && !header.hidden) {
                const value =
                  typeof row[key] === "boolean"
                    ? row[key].toString()
                    : row[key];
                return (
                  <div key={index}>
                    <b>{header.text}: </b>
                    {value}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
      formatExtraData: {
        headers: columns,
      },
    },
  ];

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
            <ToolkitProvider
              keyField="id"
              data={userSynchronizationLog}
              columns={isMobile ? mobileColumns : columns}
              search
              >
              {(props) => (
                <div>
                  <div style={{ paddingBottom: "10px", paddingTop: "10px", display: "flex" }}>
                    <SearchBar {...props.searchProps} placeholder={t("Search")} />
                  </div>

                  <Spinner spinning={isLoadingUserSynchronizationLog} />
                  {!isLoadingUserSynchronizationLog && (
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      pagination={paginationFactory(pagination)}
                      noDataIndication={t("No Errors Found")}
                      striped
                      hover
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(ErrorLogsTable);