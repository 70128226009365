import React, { useState, useEffect } from "react";
import SchedulerComponent from "./Scheduler";

import { Card, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import {
  getRHBolsaHorasConfiguration,
  getRHBolsaHorasUsersGroup,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import {
  getGruposTrabalhoRemoto,
  postMarcacaoFerias,
  setErrorPostMarcacaoFeriasConfirm,
} from "~/store/ducks/portalrh/actionTypes";
import { withNamespaces } from "react-i18next";

import Legend from "./Legend";

const VacationManagement = ({
  portalRhMenus,
  designacao,
  path,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  setLoadEventsStart,
  hideChangeView = false,
  pAVMG = false,
  t,
}) => {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [grupos, setgrupos] = useState("");
  const [activeButton, setActiveButton] = useState("");

  const { user } = useSelector((state) => state.globalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  });

  useEffect(() => {
    if (Object.keys(organization).length < 1)
      dispatch(getOrganizationById(user?.organization?.id));
  }, [user]);

  useEffect(() => {
    if (Object.keys(organization).length > 0)
      dispatch(getRHBolsaHorasConfiguration(organization.id));
  }, [organization]);

  useEffect(() => {
    if (!isEditable) {
      const payload = {
        label: "vacationsManagement",
        typeData: pAVMG ? "PE" : "AC",
      };
      dispatch(getGruposTrabalhoRemoto(payload));
    }
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
    dispatch(postMarcacaoFerias({}));
  }, []);

  return (
    <div className="main-card-v2" style={{ overflow: "auto", scrollY: "auto" }}>
      <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
        <Card.Header className="justify-content-between">
          <h6>{t("menu.portalrh.subMenus.vacationsManagement")}</h6>
        </Card.Header>
        <Card.Body style={{ minHeight: isMobile ? "auto" : "" }}>
          <SchedulerComponent
            portalRhMenus={portalRhMenus}
            designacao={designacao}
            path={path}
            isEditable={isEditable}
            showCalendarAndTable={showCalendarAndTable}
            loadEventsStart={loadEventsStart}
            setLoadEventsStart={setLoadEventsStart}
            selectedUser={isEditable ? undefined : selectedUser}
            setSelectedUser={setSelectedUser}
            grupos={grupos}
            setgrupos={setgrupos}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            hideChangeView={hideChangeView}
          />
        </Card.Body>
        <Card.Footer>
          <Legend isEditable={isEditable} isMobile={isMobile} />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default withNamespaces()(VacationManagement);
