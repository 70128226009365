import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import {
  PORTALRH_MIDDLEWARE,
  RH_MIDDLEWARE,
  IFLOW_APP,
  BPM_MIDDLEWARE,
} from "~/utils/constants";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import oauth from "~/utils/oauth";

import {
  setCheck,
  setblockCheckIn,
  setDocuments,
  setDocument,
  setSuccessDelete,
  redirectUserTo,
  setOperations,
  setPreviewBinary,
  setCollaborators,
  setScheduler,
  clearCheck,
  setRequestsByUsername,
  setRequestsByUsernameWithType,
  setUserPayData,
  setUserInformations,
  setUserSynchronizationLog,
  setObjectives,
  setObjectiveByID,
  setEvaluationSystems,
  setEvaluationPeriods,
  setDocsDisclaimers,
  stopEditingDocsDisclaimers,
  stopUploadSchedule,
  setEntities,
  setLogs,
  stopUploadAttendance,
  setEntitiesAttendance,
  setAttendanceLogs,
  setExportLogs,
  setExportAbsenceLogs,
  setExportAbsenceData,
  setGroupUsers,
  setRegistoFaltas,
  successAdded,
  setTipoAusencias,
  setTipoAusenciasIDONIC,
  setTrabalhoRemoto,
  setErrorPostTrabalhoRemoto,
  setErrorPostTrabalhoRemotoConfirm,
  setInformationProcessRemoteWork,
  setGruposTrabalhoRemoto,
  setTrabalhoRemotoInfoSaldo,
  setapproveDeleteRemoveDayRemoteWork,
  setExportVals,
  setMarcacaoFerias,
  setErrorPostMarcacaoFerias,
  setErrorPostMarcacaoFeriasConfirm,
  setInformationProcessMarcacaoFerias,
  setapproveDeleteRemoveMarcacaoFerias,
  setMarcacaoFeriasCalendario,
  setRemoveDaysTrabalhoRemoto,
  setSimuladorFerias,
  setRequestsMyTasksWidget,
  setRequestsMyTasksWidgetPIDs,
  setConsumoSaldo,
  setInformationProcessAuthorization,
  setHorarioOptions,
  postHorarioOptionsMessage,
  setTotalSaldosTabela,
  setExportVacations
} from "./actionTypes";

import { activeError, activeSuccess } from "~/components/Messages/actions";

export function* getCheck() {
  try {
    const resp = yield http.get(
      "/api/timesheet/ischeckinorout",
      PORTALRH_MIDDLEWARE
    );
    yield put(setCheck(resp.data.response));
  } catch (e) {
    yield put(clearCheck());
  }
}

export function* toggleCheck(t) {
  const path = "personalarea/scheduler";
  try {
    const responseCheckinout = yield http.get(
      "/api/timesheet/checkinout",
      PORTALRH_MIDDLEWARE
    );
    const response = yield http.get(
      "/api/timesheet/ischeckinorout",
      PORTALRH_MIDDLEWARE
    );
    yield put(setCheck(response.data.response));

    if (responseCheckinout.data.response === "off") {
      yield put(setblockCheckIn(true));
    } else {
      yield put(redirectUserTo(path));
    }
  } catch (e) {
    yield put(activeError("general.error"));
    yield put(clearCheck());
  }
}

export function* getDocuments() {
  try {
    const resp = yield http.get(
      "/api/document-management/document/all",
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getDocumentsbyUsername(obj) {
  try {
    const resp = yield http.get(
      `/api/document-management/document/all/${obj.username}`,
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* saveDocumentList({
  docList,
  uploadEmMassa,
  isForAll,
  notificationOption,
}) {
  let resp;
  try {
    const data = new FormData();
    for (let i = 0; i < docList.length; i++) {
      data.append("file", docList[i].file);
    }
    data.append(
      "document",
      new Blob([JSON.stringify(docList)], { type: "application/json" })
    );

    data.append(
      "isMassUpload",
      new Blob([JSON.stringify(uploadEmMassa)], { type: "application/json" })
    );
    data.append(
      "isForAll",
      new Blob([JSON.stringify(isForAll)], { type: "application/json" })
    );

    data.append(
      "notificationOption",
      new Blob([JSON.stringify(notificationOption)], {
        type: "application/json",
      })
    );

    resp = yield http.post(
      "/api/document-management/document/save",
      data,
      PORTALRH_MIDDLEWARE
    );

    if (resp) {
      yield put(
        activeSuccess("portalrh.documentManagement.saveDocumentSuccessMessage")
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(
      activeError(
        response?.data?.response
          ? `notranslate:${response?.data?.response}`
          : "portalrh.documentManagement.saveErrorMessage"
      )
    );
  }
}

export function* saveDocumentListWithUsername({
  docList,
  uploadEmMassa,
  username,
  isForAll,
}) {
  let resp;
  try {
    const data = new FormData();
    for (let i = 0; i < docList.length; i++) {
      data.append("file", docList[i].file);
    }
    data.append(
      "document",
      new Blob([JSON.stringify(docList)], { type: "application/json" })
    );

    data.append(
      "isMassUpload",
      new Blob([JSON.stringify(uploadEmMassa)], { type: "application/json" })
    );
    data.append("username", username);
    data.append(
      "isForAll",
      new Blob([JSON.stringify(isForAll)], { type: "application/json" })
    );
    resp = yield http.post(
      "/api/document-management/document/save/username",
      data,
      PORTALRH_MIDDLEWARE
    );

    if (resp) {
      yield put(
        activeSuccess("portalrh.documentManagement.saveDocumentSuccessMessage")
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(
      activeError(
        response?.data?.response
          ? `notranslate:${response?.data?.response}`
          : "portalrh.documentManagement.saveErrorMessage"
      )
    );
  }
}

export function* deleteDocument({ docId }) {
  try {
    const resp = yield http.delete(
      `/api/document-management/document/delete/${docId}`,
      PORTALRH_MIDDLEWARE
    );
    if (resp.status == 200) {
      yield put(setSuccessDelete());
    }
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.deleteErrorMessage"));
  }
}

export function* getDocumentBinary({ id }) {
  try {
    const resp = yield http.get(
      `api/document-management/document/content/${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setDocument(resp.data));
  } catch (e) {
    yield put(setDocument(null));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getOperations({ t }) {
  try {
    const resp = yield http.get(`api/operation/all`, PORTALRH_MIDDLEWARE);
    resp.data.map((el) => (el.name = t("portalrh.operations." + el.name)));
    if (Array.isArray(resp.data)) yield put(setOperations(resp.data));
  } catch (e) {
    yield put(setOperations([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getPreviewBinary({ id }) {
  try {
    const resp = yield http.get(
      `api/document-management/document/content/${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setPreviewBinary(resp.data));
  } catch (e) {
    yield put(setPreviewBinary(null));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* updateDocument({ document }) {
  try {
    const data = new FormData();
    data.append(
      "document",
      new Blob([JSON.stringify(document)], { type: "application/json" })
    );
    const resp = yield http.post(
      "/api/document-management/document/update",
      data,
      PORTALRH_MIDDLEWARE
    );
    if (resp && !document.seen)
      yield put(
        activeSuccess(
          "portalrh.documentManagement.updateDocumentSuccessMessage"
        )
      );
  } catch (e) {
    yield put(
      activeError("portalrh.documentManagement.updateDocumentErrorMessage")
    );
  }
}

export function* getCollaborators() {
  try {
    const resp = yield http.get(`api/user/colaboradores`, PORTALRH_MIDDLEWARE);
    if (Array.isArray(resp.data)) yield put(setCollaborators(resp.data));
  } catch (e) {
    yield put(setCollaborators([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getExportVals({ payload }) {
  const { type, list, monthName, yearDate, dataInicio, dataFim, ids } = payload;
  let url = "";
  let message = "";
  let fileName = "";
  let payloadSend;
  if (type !== "criteria") {
    url = `api/trabalho-remoto/export-remote-work`;
    if (type === "screen") {
      message = "exportedScreen";
      fileName = `exported_data_${monthName}_${yearDate}.csv`;
    }
    if (type === "year") {
      message = "exportedYear::" + yearDate;
      fileName = `exported_data_${yearDate}.csv`;
    }
    payloadSend = list;
  } else {
    url = "api/trabalho-remoto/export-remote-work-by-filters";
    message = "exportedCriteria";
    fileName = `exported_data.csv`;
    payloadSend = {
      ids,
      dataInicio,
      dataFim,
    };
  }

  try {
    const resp = yield http.post(url, payloadSend, {
      ...PORTALRH_MIDDLEWARE,
      responseType: "arraybuffer", // Important for binary data like CSV
    });

    const blob = new Blob([resp.data], { type: "text/csv;charset=ISO-8859-1" });
    saveAs(blob, fileName);
    yield put(setExportVals(message));
  } catch (e) {
    yield put(setExportVals(""));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

// export function* getExportedTable({ payload }) {
//   const url = "api/timesheet/export-timesheet";
//   const fileName = `exported_timesheet.csv`;
//   try {
//     const resp = yield http.post(url, payload.timesheet, {
//       ...PORTALRH_MIDDLEWARE,
//       responseType: "arraybuffer",
//     });

//     const blob = new Blob([resp.data], { type: "text/csv;charset=ISO-8859-1" });
//     saveAs(blob, fileName);
//     yield put(setExportVals("download"));
//   } catch (e) {
//     yield put(setExportVals("error"));
//     yield put(activeError("portalrh.documentManagement.errorMessage"));
//   }
// }

export function* getExportedTable({ payload }) {
  const url = `api/timesheet/export-timesheet?numFuncionario=${payload.numFunc}`;
  try {
    const resp = yield http.post(url, payload.timesheet, {
      ...PORTALRH_MIDDLEWARE,
      responseType: "arraybuffer",
    });

    // Verifique se o cabeçalho Content-Disposition está presente
    const contentDisposition =
      resp.headers["content-disposition"] ||
      resp.headers["Content-Disposition"];
    let fileName = "exported_timesheet.csv"; // Valor padrão

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (fileNameMatch && fileNameMatch.length > 1) {
        fileName = fileNameMatch[1];
      }
    }

    const blob = new Blob([resp.data], { type: "text/csv;charset=ISO-8859-1" });
    saveAs(blob, fileName);
    yield put(setExportVals("download"));
  } catch (e) {
    yield put(setExportVals("error"));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getScheduler({ payload }) {
  try {
    const resp = yield http.post(
      `api/timesheet/folha-de-ponto`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    yield put(setScheduler(resp.data));
  } catch (e) {
    yield put(setScheduler({}));
  }
}

export function* getTotalSaldosTabela({ payload }) {
  try {
    const resp = yield http.post(
      `api/timesheet/folha-de-ponto-tabela`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    yield put(setTotalSaldosTabela(resp.data));
  } catch (e) {
    yield put(setTotalSaldosTabela([]));
  }
}

export function* getHorarioOptions({ entidade }) {
  try {
    const respFuncao = yield http.get(
      `api/funcao/all?emp=${entidade}`,
      RH_MIDDLEWARE
    );
    const respLocal = yield http.get(
      `api/local-trabalho/all?emp=${entidade}`,
      RH_MIDDLEWARE
    );
    const respHorario = yield http.get(
      `api/horario/all?emp=${entidade}`,
      RH_MIDDLEWARE
    );
    const horarioOptions = {
      funcao: respFuncao.data || [],
      local: respLocal.data || [],
      horario: respHorario.data || [],
    };
    yield put(setHorarioOptions(horarioOptions));
  } catch (e) {
    yield put(setHorarioOptions({}));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* postHorarioOptions({ payload }) {
  try {
    // yield http.post(`api/horario/update`, payload, RH_MIDDLEWARE);
    yield put(postHorarioOptionsMessage("success"));
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getTrabalhoRemoto({ payload }) {
  try {
    const feriasFolgas = yield http.get(
      `api/retornoHorarioFuncionarioFolgasFeriados?entidade=${payload.entidade}&numeroFuncionario=${payload.numFunc}&ano=${payload.ano}`,
      RH_MIDDLEWARE
    );
    const diasMarcados = yield http.get(
      `api/trabalho-remoto/find-by-user?usrid=${payload.usrId}&ano=${payload.ano}`,
      PORTALRH_MIDDLEWARE
    );
    const trabalhoRemotoData = {
      feriados: feriasFolgas.data.feriados || [],
      folgas: feriasFolgas.data.folgas || [],
      diasmarcados: diasMarcados.data || [],
    };
    yield put(setTrabalhoRemoto(trabalhoRemotoData));
  } catch (e) {
    yield put(setTrabalhoRemoto([]));
  }
}

export function* getInfoSaldoTrabalhoRemoto({ payload }) {
  try {
    const resp = yield http.get(
      `api/trabalho-remoto/situacao-remote-work?usrid=${payload.usrId}&ano=${payload.ano}&mes=${payload.mes}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setTrabalhoRemotoInfoSaldo(resp.data));
  } catch (e) {
    yield put(setTrabalhoRemotoInfoSaldo({}));
  }
}

export function* getGruposTrabalhoRemoto({ payload }) {
  try {
    const resp = yield http.get(
      `api/get-group-users-by-label?label=${payload.label}?type=${payload.typeData}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setGruposTrabalhoRemoto(resp.data));
  } catch (e) {
    yield put(setGruposTrabalhoRemoto([]));
  }
}

export function* getAllTrabalhoRemoto({ payload }) {
  try {
    const resp = yield http.post(
      `api/trabalho-remoto/find-by-users`,
      payload,
      PORTALRH_MIDDLEWARE
    );

    const userIdColors = {};

    // Adiciona uma cor aleatória para cada userId único em diasmarcadosTeam
    const diasmarcadosTeamWithColor = resp.data.map((event) => {
      const { userId } = event;
      if (!userIdColors[userId]) {
        userIdColors[userId] = getRandomColor();
      }
      return {
        ...event,
        color: userIdColors[userId],
      };
    });

    const trabalhoRemotoData = {
      diasmarcadosTeam: diasmarcadosTeamWithColor || [],
      feriados: [],
      folgas: [],
      diasmarcados: [],
    };

    yield put(setTrabalhoRemoto(trabalhoRemotoData));
  } catch (e) {
    yield put(setTrabalhoRemoto([]));
  }
}

// Função para obter uma cor aleatória hexadecimal
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function* postTrabalhoRemoto({ payload }) {
  try {
    if (Object.keys(payload).length > 0) {
      const resp = yield http.post(
        `api/trabalho-remoto/validate-remote-work`,
        payload,
        PORTALRH_MIDDLEWARE
      );
      yield put(setErrorPostTrabalhoRemoto(resp.data));
    } else {
      yield put(setErrorPostTrabalhoRemoto({}));
    }
  } catch (e) {
    yield put(setErrorPostTrabalhoRemoto({}));
  }
}

export function* postTrabalhoRemotoConfirm({ payload }) {
  try {
    yield http.post(
      `api/trabalho-remoto/insert-remote-work`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    yield put(setErrorPostTrabalhoRemotoConfirm("sucess"));
  } catch (e) {
    yield put(setErrorPostTrabalhoRemotoConfirm("error"));
  }
}

export function* postRemoveDaysTrabalhoRemoto({ payload }) {
  try {
    yield http.post(
      `api/trabalho-remoto/update-state`,
      payload.ids,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRemoveDaysTrabalhoRemoto("delete"));
  } catch (e) {
    yield put(setRemoveDaysTrabalhoRemoto("errorDelete"));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getDocumentsAdmin() {
  try {
    const resp = yield http.get(
      "/api/document-management/document/find/rhadmin",
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* findDocumentsByFilters({ filters }) {
  try {
    const resp = yield http.post(
      "/api/document-management/document/find/rhadmin",
      filters,
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getRequestsByUsername({ username, procGeneric }) {
  try {
    if (procGeneric) {
      const resp = yield http.get(
        `api/request/all-by-username?username=${username}?procGeneric=${1}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setRequestsByUsername(resp.data));
    } else {
      const resp = yield http.get(
        `api/request/all-by-username?username=${username}?procGeneric=${0}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setRequestsByUsername(resp.data));
    }
  } catch (e) {
    yield put(setRequestsByUsername([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getRequestsByUsernameWithType({
  username,
  from,
  searchFilter,
}) {
  try {
    const resp = yield http.get(
      `api/request/all-by-username-with-type?username=${username}?type=${from}?operation=${searchFilter}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRequestsByUsernameWithType(resp.data));
  } catch (e) {
    yield put(setRequestsByUsernameWithType([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getInformationProcessRemoteWork({ payload }) {
  try {
    const resp = yield http.get(
      `api/request/detail?requestid=${payload.id}`,
      PORTALRH_MIDDLEWARE
    );
    const data = {
      ...resp.data,
      trabalhoremoto: resp.data.trabalhoremoto || [],
    };
    yield put(setInformationProcessRemoteWork(data));
  } catch (e) {
    yield put(setInformationProcessRemoteWork([]));
  }
}

export function* approveDeleteRemoveDayRemoteWork({ payload }) {
  try {
    const resp = yield http.post(
      `api/request/authorize-response`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    yield put(setapproveDeleteRemoveDayRemoteWork(resp.data));
  } catch (e) {
    yield put(setapproveDeleteRemoveDayRemoteWork([]));
  }
}

export function* getInformationProcessMarcacaoFerias({ payload }) {
  try {
    const resp = yield http.get(
      `api/request/detail?requestid=${payload.id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setInformationProcessMarcacaoFerias(resp.data));
  } catch (e) {
    yield put(setInformationProcessMarcacaoFerias([]));
  }
}

export function* getInformationProcessAuthorization({ payload }) {
  try {
    const resp = yield http.get(
      `api/request/detail?requestid=${payload.id}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setInformationProcessAuthorization(resp.data));
  } catch (e) {
    yield put(setInformationProcessAuthorization({}));
  }
}

export function* approveDeleteRemoveMarcacaoFerias({ payload }) {
  try {
    const resp = yield http.post(
      `api/request/generic-response`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    yield put(setapproveDeleteRemoveMarcacaoFerias(resp.data));
  } catch (e) {
    yield put(setapproveDeleteRemoveMarcacaoFerias([]));
  }
}

export function* checkUpdateByUsername({ username }) {
  try {
    const resp = yield http.get(
      `api/user/check-update-by-username?username=${username}`,
      PORTALRH_MIDDLEWARE
    );
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getUserPayData({ funcionarioNum }) {
  try {
    const resp = yield http.get(
      `api/open/paymentdata/find-by-funcionarionum?funcionarioNum=${funcionarioNum}`,
      RH_MIDDLEWARE
    );

    yield put(setUserPayData(resp.data));
  } catch (e) {
    yield put(setUserPayData([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getByUsername({ username }) {
  try {
    const resp = yield http.get(
      `api/user/find-user-informations-by-username?username=${username}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setUserInformations(resp.data));
  } catch (e) {
    yield put(setUserInformations([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getUserSynchronizationLog() {
  try {
    const resp = yield http.get(
      `api/get/user-synchronization-log`,
      RH_MIDDLEWARE
    );
    yield put(setUserSynchronizationLog(resp.data));
  } catch (e) {
    yield put(setUserSynchronizationLog([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getObjectives() {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/objectives`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setObjectives(resp.data));
  } catch (e) {
    yield put(setObjectives([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getObjectiveByID({ obj_id }) {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/objective-by-id?obj_id=${obj_id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setObjectiveByID(resp.data));
  } catch (e) {
    yield put(setObjectiveByID([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getEvaluationSystems() {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/evaluationSystems`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setEvaluationSystems(resp.data));
  } catch (e) {
    yield put(setEvaluationSystems([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getEvaluationPeriods({ sis_id }) {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/evaluationPeriods?sis_id=${sis_id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setEvaluationPeriods(resp.data));
  } catch (e) {
    yield put(setEvaluationPeriods([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//OPERATION ANNEX DOCS #####################################
export function* updateOperationStates({ operations }) {
  try {
    yield http.put(
      `api/operation/update-annex-docs`,
      JSON.stringify(operations),
      PORTALRH_MIDDLEWARE
    );

    yield put(
      activeSuccess("portalrh.annexDocs.documentsAnnexationUpdatedSuccessfully")
    );
  } catch (e) {
    yield put(
      activeError("portalrh.annexDocs.errorUpdatingDocumentsAnnexation")
    );
  }
}

//DOCS DISCLAIMERS #########################################
export function* getDocsDisclaimers() {
  try {
    const resp = yield http.get(`api/docs-disclaimer/all`, PORTALRH_MIDDLEWARE);

    yield put(setDocsDisclaimers(resp.data));
  } catch (e) {
    yield put(setDocsDisclaimers([]));
  }
}

export function* updateDocsDisclaimer({ docsDisclaimer }) {
  try {
    yield http.put(
      `api/docs-disclaimer/`,
      JSON.stringify(docsDisclaimer),
      PORTALRH_MIDDLEWARE
    );

    yield put(
      activeSuccess(
        "portalrh.docsDisclaimer.documentsDisclaimerUpdatedSuccessfully"
      )
    );
  } catch (e) {
    yield put(
      activeError("portalrh.docsDisclaimer.errorUpdatingDocumentsDisclaimer")
    );
  }
  yield put(stopEditingDocsDisclaimers());
}

//Export Schedule service for IHG web time integration
export function* uploadSchedule({ uploadData, selectedEntityId }) {
  try {
    yield http.post(
      `/api/innux/escalas?idEntidade=${selectedEntityId}`,
      uploadData,
      PORTALRH_MIDDLEWARE
    );

    yield put(activeSuccess("portalrh.exportSchedule.successWarnning"));
  } catch (e) {
    yield put(activeError("portalrh.exportSchedule.errorWarnning"));
  }
  yield put(stopUploadSchedule());
}

export function* uploadAttendance({ uploadData, selectedEntityId }) {
  try {
    yield http.post(
      `/api/importAttendance?idEntidade=${selectedEntityId}`,
      uploadData,
      RH_MIDDLEWARE
    );

    yield put(activeSuccess("portalrh.importAttendance.successWarnning"));
  } catch (e) {
    yield put(activeError("portalrh.importAttendance.errorWarning"));
  }
  yield put(stopUploadAttendance());
}

export function* getEntities() {
  try {
    const resp = yield http.get(`api/innux/entidades/all`, PORTALRH_MIDDLEWARE);

    yield put(setEntities(resp.data));
  } catch (e) {
    yield put(setEntities([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getEntitiesAttendance() {
  try {
    const resp = yield http.get(`api/attendance/entities`, PORTALRH_MIDDLEWARE);

    yield put(setEntitiesAttendance(resp.data));
  } catch (e) {
    yield put(setEntitiesAttendance([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getLogs() {
  try {
    const resp = yield http.get("api/innux/get-logs", PORTALRH_MIDDLEWARE);
    yield put(setLogs(resp.data));
  } catch (e) {
    yield put(setLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getAttendanceLogs() {
  try {
    const resp = yield http.get("api/attendanceLogs", RH_MIDDLEWARE);
    yield put(setAttendanceLogs(resp.data));
  } catch (e) {
    yield put(setAttendanceLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getExportLogs({ logs }) {
  try {
    const resp = yield http.post(`/api/open/export-logs`, logs, RH_MIDDLEWARE);
    yield put(setExportLogs(resp.data));
  } catch (e) {
    yield put(setExportLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getExportAbsenceLogs() {
  try {
    const resp = yield http.get("api/export-logs-txt", PORTALRH_MIDDLEWARE);
    yield put(setExportAbsenceLogs(resp.data));
    yield put(successAdded(false));
  } catch (e) {
    yield put(successAdded(false));
    yield put(setExportAbsenceLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getExportAbsenceData() {
  try {
    const resp = yield http.get(`api/export-data-txt`, PORTALRH_MIDDLEWARE);
    yield put(setExportAbsenceData(resp.data));
    yield put(successAdded(false));
  } catch (e) {
    yield put(setExportAbsenceData([]));
    yield put(successAdded(false));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* exportTxt({ payload }) {
  yield put(successAdded(false));
  try {
    const resp = yield http.post(
      `api/export-txt`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    const blob = new Blob([resp.data], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_table.txt");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);

    yield put(successAdded(true));
  } catch (e) {
    yield put(successAdded(false));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getGroupUsers({ typeData }) {
  try {
    const resp = yield http.get(
      `api/get-group-users?type=${typeData}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setGroupUsers(resp?.data));
  } catch (e) {
    yield put(setGroupUsers([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getRegistoFaltas({ payload }) {
  let url = undefined;
  if (payload.tipo == "Faltas") {
    url = `api/registo-faltas/by-date`;
  } else if (payload.tipo == "Férias") {
    url = `api/registo-faltas/by-date`;
  } else {
    url = `api/registo-faltas/by-date`;
  }

  if (url != undefined) {
    yield callRegistoFaltas(payload, url);
  }
}

function* callRegistoFaltas(payload, url) {
  try {
    const resp = yield http.put(url, payload, RH_MIDDLEWARE);

    yield put(setRegistoFaltas(resp?.data));
  } catch (e) {
    yield put(setRegistoFaltas([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getTipoAusencias() {
  try {
    const resp = yield http.get(`api/tipo-ausencia/all`, RH_MIDDLEWARE);

    yield put(setTipoAusencias(resp?.data));
  } catch (e) {
    yield put(setTipoAusencias([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getTipoAusenciasIDONIC() {
  try {
    const resp = yield http.get(`api/tipo-ausencia/idonic/all`, RH_MIDDLEWARE);

    yield put(setTipoAusenciasIDONIC(resp?.data));
  } catch (e) {
    yield put(setTipoAusenciasIDONIC([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getMarcacaoFerias({ payload, simulador }) {
  try {
    if (simulador && (simulador !== undefined || simulador !== null)) {
      const calendario = yield http.get(
        `api/retornoSituacaoeMarcacaoFeriasFuncionario?entidade=${payload.entidade}&numeroFuncionario=${payload.numFunc}&ano=${payload.ano}`,
        RH_MIDDLEWARE
      );
      const simuladorFerias = {
        saldoAprovado: calendario.data.saldoAprovado,
        saldoPendente: calendario.data.saldoPendente,
      };
      yield put(setSimuladorFerias(simuladorFerias));
    } else {
      const calendario = yield http.get(
        `api/retornoSituacaoeMarcacaoFeriasFuncionario?entidade=${payload.entidade}&numeroFuncionario=${payload.numFunc}&ano=${payload.ano}`,
        RH_MIDDLEWARE
      );
      const folgas = yield http.get(
        `api/retornoHorarioFuncionarioFolgasFeriados?entidade=${payload.entidade}&numeroFuncionario=${payload.numFunc}&ano=${payload.ano}`,
        RH_MIDDLEWARE
      );
      const marcacaoFeriasData = {
        feriados: folgas.data.feriados || [],
        folgas: folgas.data.folgas || [],
        ferias: [],
        datasMarcacoes: calendario.data.datasMarcacoes || [],
        datasGozados: calendario.data.datasGozados || [],
        feriasPendentes: calendario.data.feriasPendentes || [],
        marcacoes: calendario.data.marcacoes || [],
        feriasPendentesMap: calendario.data.feriasPendentesMap || {},
      };
      const situation = {
        cAnoAnterior: calendario.data.contadorAnoAnterior || 0,
        cAnoCorrente: calendario.data.contadorAnoCorrente || 0,
        assiduidade: calendario.data.diasAssiduidade || 0,
        aprovadas: calendario.data.diasFeriasAprovados || 0,
        pendentes: calendario.data.diasFeriasPendentes || 0,
        porGozar: calendario.data.diasPorGozar || 0,
        gAnoAnterior: calendario.data.gozadosAnoAnterior || 0,
        gAnoCorrente: calendario.data.gozadosAnoCorrente || 0,
        outros: calendario.data.outrosDiasFerias || 0,
        sAprovado: calendario.data.saldoAprovado || 0,
        sPendente: calendario.data.saldoPendente || 0,
        dFerias: calendario.data.situacao[0].diasFerias || 0,
        dGozarAnoAnterior: calendario.data.situacao[0].diasPorGozarAnoAnt || 0,
        totaisFerias: calendario.data.diasTotaisFerias || 0,
        sPendenteAnoAnt:
          calendario.data.contadorPendentesGozadosAprovadosAnoAnterior || 0,
      };
      yield put(setMarcacaoFerias(marcacaoFeriasData));
      yield put(setMarcacaoFeriasCalendario(situation));
    }
  } catch (e) {
    yield put(setMarcacaoFerias({}));
    yield put(setMarcacaoFeriasCalendario([]));
  }
}

export function* postExportVacations({ payload }) {
  try {
    const resp = yield http.post(
      `api/listaMapaFerias`,
      payload,
      RH_MIDDLEWARE
    );
    yield put(setExportVacations(resp.data));
  } catch (e) {
    yield put(setExportVacations({}));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getAllMarcacaoFerias({ payload }) {
  try {
    const resp = yield http.post(
      `api/get-ferias-equipa`,
      payload,
      PORTALRH_MIDDLEWARE
    );
    const userIdColors = {};

    const diasmarcadosTeamWithColor = resp.data.flatMap((userObj) => {
      const userId = Object.keys(userObj).find((key) => key !== "nome");
      const dates = userObj[userId];
      const nome = userObj.nome[0];
      const fixedColor = "#007bff"; // Define the fixed color
      if (!userIdColors[userId]) {
        userIdColors[userId] = fixedColor;
      }
      return dates.map((data) => ({
        userId,
        nome,
        data,
        color:
          payload.employee && userId === payload.employee
            ? "red"
            : userIdColors[userId],
      }));
    });

    //ISTO METE CORES RANDOM
    // const diasmarcadosTeamWithColor = resp.data.flatMap((userObj) => {
    //   const userId = Object.keys(userObj).find((key) => key !== "nome");
    //   const dates = userObj[userId];
    //   const nome = userObj.nome[0];

    //   if (!userIdColors[userId]) {
    //     userIdColors[userId] = getRandomColor();
    //   }
    //   return dates.map((data) => ({
    //     userId,
    //     nome,
    //     data,
    //     color: userIdColors[userId],
    //   }));
    // });

    const trabalhoRemotoData = {
      diasmarcadosTeam: diasmarcadosTeamWithColor || [],
      feriados: [],
      folgas: [],
      datasMarcacoes: [],
      ferias: [],
      feriasPendentes: [],
      marcacoes: [],
      feriasPendentesMap: {},
      datasGozados: [],
    };
    yield put(setMarcacaoFerias(trabalhoRemotoData));
  } catch (e) {
    yield put(setMarcacaoFerias({}));
  }
}

export function* postMarcacaoFerias({ payload }) {
  try {
    if (Object.keys(payload).length > 0) {
      yield http.post(
        `api/inserir-pedido-ferias`,
        payload,
        PORTALRH_MIDDLEWARE
      );
      yield put(setErrorPostMarcacaoFeriasConfirm("sucess"));
    } else {
      yield put(setErrorPostMarcacaoFeriasConfirm(""));
    }
  } catch (e) {
    yield put(setErrorPostMarcacaoFeriasConfirm("error"));
  }
}

export function* cancelDayOffRequest({ payload }) {
  try {
    yield http.post(
      `api/inserir-pedido-cancelar-ferias`,
      payload,
      PORTALRH_MIDDLEWARE
    );
  } catch (e) {
    console.error(e);
  }
}

export function* postMarcacaoFeriasConfirm({ payload }) {
  try {
    if (Object.keys(payload).length > 0) {
      yield http.post(
        `api/trabalho-remoto/insert-remote-work`,
        payload,
        PORTALRH_MIDDLEWARE
      );
      yield put(setErrorPostMarcacaoFeriasConfirm("sucess"));
    } else {
      yield put(setErrorPostMarcacaoFeriasConfirm(""));
    }
  } catch (e) {
    yield put(setErrorPostMarcacaoFeriasConfirm("error"));
  }
}

export function* getRequestsMyTasksWidget({ payload }) {
  try {
    const resp = yield http.post(
      `fin/pending-activities`,
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setRequestsMyTasksWidget(resp.data));
  } catch (e) {
    yield put(setRequestsMyTasksWidget([]));
  }
}

export function* getRequestsMyTasksWidgetPIDs({ usernamePortalRh }) {
  try {
    const resp = yield http.get(
      `api/request/get-excluded-pids?username=${usernamePortalRh}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRequestsMyTasksWidgetPIDs(resp.data));
  } catch (e) {
    yield put(setRequestsMyTasksWidgetPIDs({}));
  }
}

export function* getConsumoSaldo() {
  try {
    const resp = yield http.get(`api/empresa`, PORTALRH_MIDDLEWARE);
    yield put(setConsumoSaldo(resp.data));
  } catch (e) {
    yield put(setConsumoSaldo([]));
  }
}
