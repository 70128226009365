import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getInformationProcessMarcacaoFerias,
  approveDeleteRemoveMarcacaoFerias,
} from "~/store/ducks/portalrh/actionTypes";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Spinner from "~/components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import "./modalCss.css";
import { format, parseISO } from "date-fns";
import { Button as ButtonMaterial, Stack, Box } from "@mui/material";
import SchedulerComponent from "../../VacationManagement/Scheduler";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert as AlertMaterial } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import {
  FaClock,
  FaCheckCircle,
  FaTimesCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import Legend from "../../VacationManagement/Legend";

const ModalMarcacaoFerias = ({
  t,
  process,
  closeButton,
  showModalMarcacaoFerias,
  handleCloseModalMarcacaoFerias,
  setmakeUpdate,
  path,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  portalRhMenus,
  designacao,
  styleSheet,
  searchFilter,
}) => {
  const {
    infoProcessMarcacaoFerias,
    isLoadingInfoProcMarcacaoFerias,
    collaborators,
  } = useSelector((state) => state.portalrhReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { genericAuthorizationState } = useSelector(
    (state) => state.portalrhReducer
  );

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const [infoData, setinfoData] = useState({});
  const [transformedData, setTransformedData] = useState({});
  const [modalConfirmation, setmodalConfirmation] = useState(false);
  const [conditionApproveRequest, setConditionApproveRequest] = useState(false);
  const [comentario, setComentario] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (process != undefined) {
      const payload = {
        id: process.id,
      };
      dispatch(getInformationProcessMarcacaoFerias(payload));
    }
  }, [process]);

  useEffect(() => {
    setinfoData(infoProcessMarcacaoFerias);
  }, [infoProcessMarcacaoFerias]);

  const buildNrDiasColumn = (_, row, index) => {
    if (row.qtDias === 1 || row.qtDias === "1") {
      return t("portalrh.workDays.diaInteiro");
    }
    return t("portalrh.workDays.diasMarcadosMeiodia");
  };

  function dateFormater(cell, row) {
    if (row.date) {
      const parsedDate = parseISO(cell);
      return (
        format(parsedDate, "dd/MM/yyyy") +
        " - " +
        (infoData.dates && infoData.dates.meioDia
          ? t("portalrh.workDays.diasMarcadosMeiodia")
          : t("portalrh.workDays.diaInteiro"))
      );
    }
  }

  const buildApprovedColumn = (_, row, index) => {
    if (row.aprovado === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (row.aprovado === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (row.aprovado === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (row.aprovado === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (row.aprovado === 5) {
      return t("portalrh.workDays.Visto");
    }
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "date",
      text: t("portalrh.requestTable.date"),
      sort: true,
      formatter: dateFormater,
    },
  ];
  const handleApproveRequest = (aceptReject) => {
    setConditionApproveRequest(aceptReject);
    setmodalConfirmation(true);
  };

  const handleSend = async () => {
    if (!conditionApproveRequest) if (comentario === "") return;
    setmodalConfirmation(false);
    let payload;
    if (conditionApproveRequest) {
      payload = {
        ped_id: process.id,
        state: 1,
        username: userNamePortalRH.username,
        comment: comentario,
      };
    } else {
      payload = {
        ped_id: process.id,
        state: 0,
        username: userNamePortalRH.username,
        comment: comentario,
      };
    }

    await dispatch(approveDeleteRemoveMarcacaoFerias(payload));

    setTimeout(() => {
      setmakeUpdate(true);
      handleCloseModalMarcacaoFerias(searchFilter, 0, true);
      setComentario("");
    }, 200);
  };

  const { SearchBar } = Search;

  const createState = (item) => {
    return (
      <>
        {item === "Pendente" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaClock style={{ color: "#FFA500" }} />
          </OverlayTrigger>
        )}
        {item === "Aprovado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaCheckCircle
              style={{
                color: "#008000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Rejeitado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Recusado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
      </>
    );
  };

  const createStateChecked = (item) => {
    const hasStateId3 = infoData.autorizacao.some(
      (autorizacao) => autorizacao.state_id === "3"
    );
    const hasStateId4 = infoData.autorizacao.some(
      (autorizacao) => autorizacao.state_id === "4"
    );
    let icon;
    if (hasStateId3) {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.Aprovado`)}</Tooltip>}
          placement="right"
        >
          {item.state_id === "3" ? (
            <FaCheckCircle style={{ color: "#008000" }} />
          ) : (
            <FaRegCircleCheck style={{ color: "#008000" }} />
          )}
        </OverlayTrigger>
      );
    } else if (hasStateId4) {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.Recusado`)}</Tooltip>}
          placement="right"
        >
          {item.state_id === "4" ? (
            <FaTimesCircle style={{ color: "#FF0000" }} />
          ) : (
            <FaRegTimesCircle style={{ color: "#FF0000" }} />
          )}
        </OverlayTrigger>
      );
    } else {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.${item.state}`)}</Tooltip>}
          placement="right"
        >
          <FaClock style={{ color: "#FFA500" }} />
        </OverlayTrigger>
      );
    }
    return icon;
  };

  useEffect(() => {
    if (infoData?.dates !== undefined) {
      const transformedDataTemp = infoData.dates.dates.map((date) => ({
        date,
      }));
      setTransformedData(transformedDataTemp);
    }
  }, [infoData]);

  return (
    <>
      {Object.keys(infoData).length > 0 &&
      Object.keys(transformedData).length > 0 ? (
        <>
          <Modal
            show={showModalMarcacaoFerias}
            dialogClassName={"modal-tarefa-97w custom-modal"}
            centered
            backdrop="static"
            onHide={() => {
              handleCloseModalMarcacaoFerias(searchFilter, 0, true);
              setComentario("");
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {infoData?.request && infoData?.request[0].type === "Novo"
                  ? t("portalrh.workDays.feriasModal")
                  : t("portalrh.workDays.feriasModalCancel")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={
                  styleSheet?.processScroll
                    ? "scrollBar-visibleModalMyTeamFerias"
                    : ""
                }
              >
                <Row>
                  <Col lg="4">
                    <div
                      style={{
                        margin: window.innerWidth <= 768 ? "0px" : "20px 30px",
                      }}
                    >
                      <div className="custom-table custom-table-small tableDateInformation scrollBar-tableDateInformation">
                        <ToolkitProvider
                          keyField="id"
                          data={
                            !isLoadingInfoProcMarcacaoFerias && transformedData
                          }
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  display: "flex",
                                }}
                              >
                                {infoData?.request[0] && (
                                  <p>
                                    {t("portalrh.workDays.pedidoFeitoPor.1")}{" "}
                                    <b>{infoData.request[0].username} </b>
                                    {t(
                                      "portalrh.workDays.pedidoFeitoPor.2"
                                    )}{" "}
                                    <b>
                                      {format(
                                        parseISO(
                                          infoData.request[0].creation_date
                                        ),
                                        "dd/MM/yyyy"
                                      )}
                                    </b>
                                  </p>
                                )}
                              </div>
                              <div style={{ display: "flex" }}>
                                {infoData?.dates.length > 1 && (
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder={t("Search")}
                                  />
                                )}
                              </div>
                              <Spinner
                                spinning={isLoadingInfoProcMarcacaoFerias}
                                wrapper
                              />
                              {!isLoadingInfoProcMarcacaoFerias && (
                                <>
                                  <h6>
                                    {t("portalrh.workDays.diasSolicitados")}
                                  </h6>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    //pagination={paginationFactory(pagination)}
                                    noDataIndication={t(
                                      "portalrh.personalData.noRequests"
                                    )}
                                    striped
                                    hover
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: window.innerWidth <= 768 ? "0px" : "20px 30px",
                      }}
                    >
                      <AlertMaterial severity="info">
                        <AlertTitle>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              {t("portalrh.workDays.lastComment")}{" "}
                              <span style={{ fontSize: "x-small" }}>
                                {!isLoadingInfoProcMarcacaoFerias &&
                                  infoData?.comentarios.length > 0 &&
                                  infoData?.comentarios && (
                                    <>
                                      {format(
                                        parseISO(infoData?.comentarios[0].data),
                                        "yyyy/MM/dd"
                                      )}{" "}
                                      - {infoData?.comentarios[0].username}
                                    </>
                                  )}
                              </span>
                            </span>
                            <span>
                              {!isLoadingInfoProcMarcacaoFerias &&
                                infoData?.comentarios.length > 0 &&
                                infoData?.comentarios &&
                                createState(infoData?.comentarios[0].estado)}
                            </span>
                          </Stack>
                        </AlertTitle>
                        {!isLoadingInfoProcMarcacaoFerias &&
                          infoData?.comentarios.length > 0 &&
                          infoData?.comentarios && (
                            <>
                              {infoData?.comentarios[0].comentario === ""
                                ? t("portalrh.workDays.emptyComment")
                                : infoData?.comentarios[0].comentario}
                            </>
                          )}
                      </AlertMaterial>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panelfirst"
                          id="panelfirst"
                        >
                          {t("portalrh.workDays.todosComentarios")}
                        </AccordionSummary>
                        <AccordionDetails className="scrollBar-autorizersDateInformation">
                          {!isLoadingInfoProcMarcacaoFerias &&
                            infoData?.comentarios.length > 0 &&
                            infoData?.comentarios.map((item, index) => (
                              <>
                                <Alert style={{ margin: "0px" }}>
                                  <AlertTitle>
                                    <span>
                                      {item.username}
                                      <span style={{ fontSize: "x-small" }}>
                                        {" "}
                                        -{" "}
                                        {format(
                                          parseISO(item.data),
                                          "yyyy/MM/dd HH:mm"
                                        )}
                                      </span>
                                    </span>
                                  </AlertTitle>{" "}
                                  <span style={{ fontSize: "small" }}>
                                    {item.comentario === ""
                                      ? t("portalrh.workDays.emptyComment")
                                      : item.comentario}
                                  </span>
                                </Alert>
                              </>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      style={{
                        margin: isMobile ? "1rem 0px" : "20px 30px",
                      }}
                      className="scrollBar-autorizersDateInformation"
                    >
                      <h6>{t("portalrh.workDays.listaAprovadores")}</h6>
                      <ListGroup>
                        {!isLoadingInfoProcMarcacaoFerias &&
                          infoData?.autorizacao.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                <>
                                  {item.username}{" "}
                                  {item.date !== null ? "-" + item.date : ""}
                                  {createStateChecked(item)}
                                </>
                              </span>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </div>
                    {infoData?.dates.length > 0 &&
                      infoData?.dates[0].comentario && (
                        <Alert key="danger" variant="danger">
                          <b>{t("portalrh.workDays.refuseMessage")}:</b>{" "}
                          {infoData?.dates[0].comentario}
                        </Alert>
                      )}
                    {+genericAuthorizationState === 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 10px",
                          marginTop: "20px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleApproveRequest(false)}
                            disabled={modalConfirmation}
                          >
                            {t("portalrh.workDays.reject")}
                          </Button>
                          <Button
                            className="card-button"
                            onClick={() => handleApproveRequest(true)}
                            style={{ marginLeft: "15px" }}
                            disabled={modalConfirmation}
                          >
                            {t("portalrh.workDays.approve")}
                          </Button>
                        </Stack>
                      </Box>
                    )}
                    {+genericAuthorizationState === 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 10px",
                          marginTop: "20px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            className="card-button"
                            onClick={() => handleApproveRequest(true)}
                            style={{ marginLeft: "15px" }}
                          >
                            {t("portalrh.workDays.takeNotice")}
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </Col>
                  <Col>
                    {!modalConfirmation ? (
                      <>
                        <SchedulerComponent
                          portalRhMenus={portalRhMenus}
                          designacao={designacao}
                          path={path}
                          isEditable={false}
                          showCalendarAndTable={
                            showCalendarAndTable && !isMobile
                          }
                          loadEventsStart={loadEventsStart}
                          hideChangeView={true}
                          infoDataWorkHour={infoData.request[0].user_id}
                          dataSelectedRequest={infoData?.trabalhoremoto}
                          //dataSelectedRequest={infoData.request[0].creation_date}
                          showAllGroups={true}
                          diasParaAprovar={infoData?.dates}
                          infoTypeRequest={infoData?.request[0].type}
                          infoEmployee={infoData?.request[0].employee_num}
                        />
                        {showCalendarAndTable && !isMobile && (
                          <Legend
                            isEditable={false}
                            {...(infoData?.request[0].type !== "Novo"
                              ? { haveMarcacaoCancelar: true }
                              : { haveMarcacao: true })}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          padding: isMobile ? "" : "3rem",
                          alignContent: "center",
                        }}
                      >
                        <Row style={{ margin: "2rem -15px" }}>
                          <Col>
                            <h6>
                              {infoData?.request &&
                              infoData?.request[0].type === "Novo"
                                ? t("portalrh.workDays.feriasModal")
                                : t("portalrh.workDays.feriasModalCancel")}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label>
                                  {!conditionApproveRequest
                                    ? t(
                                        "portalrh.workDays.messagerefuseMessage"
                                      )
                                    : t("portalrh.workDays.messagecomentario")}
                                </Form.Label>
                                <Form.Control
                                  style={{
                                    width: "100%",
                                    ...(isMobile ? { padding: "0" } : {}),
                                  }}
                                  as="textarea"
                                  rows={isMobile ? 5 : 3}
                                  value={comentario}
                                  onChange={(e) =>
                                    setComentario(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "0px 10px",
                                marginTop: "20px",
                              }}
                            >
                              <Stack direction="row" spacing={2}>
                                <Button
                                  className="card-button-red"
                                  onClick={() => {
                                    setComentario("");
                                    setmodalConfirmation(false);
                                  }}
                                  style={{ marginLeft: "15px" }}
                                >
                                  {t("portalrh.workDays.cancel")}
                                </Button>
                                <Button
                                  className="card-button"
                                  onClick={() => {
                                    setComentario("");
                                    handleSend();
                                  }}
                                  style={{ marginLeft: "15px" }}
                                >
                                  {t("portalrh.workDays.confirmDays")}
                                </Button>
                              </Stack>
                            </Box>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingInfoProcMarcacaoFerias} wrapper={true} />
      )}
      <Modal
        show={false}
        dialogClassName={"modal-tarefa-50w custom-modal"}
        centered
        backdrop="static"
        onHide={() => {
          setmodalConfirmation(false);
          setComentario("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoData?.request && infoData?.request[0].type === "Novo"
              ? t("portalrh.workDays.feriasModal")
              : t("portalrh.workDays.feriasModalCancel")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    {!conditionApproveRequest
                      ? t("portalrh.workDays.messagerefuseMessage")
                      : t("portalrh.workDays.messagecomentario")}
                  </Form.Label>
                  <Form.Control
                    style={{ width: "100%" }}
                    as="textarea"
                    rows={3}
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 10px",
                  marginTop: "20px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Button
                    className="card-button"
                    onClick={() => handleSend()}
                    style={{ marginLeft: "15px" }}
                  >
                    {t("portalrh.workDays.confirmDays")}
                  </Button>
                </Stack>
              </Box>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default withNamespaces()(ModalMarcacaoFerias);
