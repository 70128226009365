import React from "react";
import Dropdown from "~/components/Dropdown";
import { Form, Row, Badge } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const StatisticsGroupFilters = ({
  handleChangeGroup,
  handleChangeOperation,
  renderGroups,
  renderOperations,
  cleanGroupDropdown,
  cleanOperationDropdown,
  setCleanGroupDropdown,
  setCleanOperationDropdown,
  selectedGroup,
  selectedOperation,
  cleanStates
}) => {
  return (
    <>
      <Form.Row style={{ marginTop: "0" }}>
        <Form.Label style={{ marginRight: "10px" }}>Grupos</Form.Label>
        <Dropdown
          list={renderGroups}
          handleSubmit={handleChangeGroup}
          emptyDefault={true}
          resetDropdown={cleanGroupDropdown}
          setResetDropdown={setCleanGroupDropdown}
        />
      </Form.Row>

      <Form.Row style={{ marginTop: "0" }}>
        <Form.Label style={{ marginRight: "10px" }}>Operação</Form.Label>
        <Dropdown
          list={renderOperations}
          handleSubmit={handleChangeOperation}
          emptyDefault={true}
          resetDropdown={cleanOperationDropdown}
          setResetDropdown={setCleanOperationDropdown}
        />
      </Form.Row>
      <Form.Row style={{ marginTop: "0" }}>
        <div
          style={{
            width: "100%",
            justifyContent: "right",
            display: "flex",
            gap: "1rem",
          }}
        >
          {(selectedGroup !== "" || selectedOperation !== "") && (
            <div className="divForTrashStatistics" onClick={cleanStates}>
              <FaTrash />
            </div>
          )}
        </div>
      </Form.Row>
    </>
  );
};

export default StatisticsGroupFilters;
