export const routesMenuForm = () => {
  return [
    { label: "uniksystem/bpm", value: "uniksystem/bpm", selected: false },
    {
      label: "uniksystem/globalsolver",
      value: "uniksystem/globalsolver",
      selected: false,
    },
    { label: "uniksystem/rh", value: "uniksystem/rh", selected: false },
    {
      label: "uniksystem/portalrh",
      value: "uniksystem/portalrh",
      selected: false,
    },
    {
      label: "uniksystem/prompts",
      value: "uniksystem/prompts",
      selected: false,
    },
    { label: "uniksystem/openai", value: "uniksystem/openai", selected: false },
    {
      label: "uniksystem/dashboard",
      value: "uniksystem/dashboard",
      selected: false,
    },
    { label: "uniksystem/gdpr", value: "uniksystem/gdpr", selected: false },
    {
      label: "uniksystem/compliance",
      value: "uniksystem/compliance",
      selected: false,
    },
    { label: "uniksystem/gai", value: "uniksystem/gai", selected: false },
    {
      label: "uniksystem/financeira",
      value: "uniksystem/financeira",
      selected: false,
    },
    {
      label: "uniksystem/datacapture",
      value: "uniksystem/datacapture",
      selected: false,
    },
    {
      label: "uniksystem/adatacapture",
      value: "uniksystem/adatacapture",
      selected: false,
    },
    {
      label: "uniksystem/validprocs",
      value: "uniksystem/validprocs",
      selected: false,
    },
    {
      label: "uniksystem/onboarding",
      value: "uniksystem/onboarding",
      selected: false,
    },
    {
      label: "uniksystem/easypay",
      value: "uniksystem/easypay",
      selected: false,
    },
    {
      label: "uniksystem/easypay/componentes",
      value: "uniksystem/easypay/componentes",
      selected: false,
    },
    {
      label: "uniksystem/liftworld/componentes",
      value: "uniksystem/liftworld/componentes",
      selected: false,
    },
    {
      label: "uniksystem/liftworld/workplan",
      value: "uniksystem/liftworld/workplan",
      selected: false,
    },
    {
      label: "uniksystem/budgetmgr",
      value: "uniksystem/budgetmgr",
      selected: false,
    },
    {
      label: "uniksystem/claimsmgt",
      value: "uniksystem/claimsmgt",
      selected: false,
    },
    {
      label: "uniksystem/easypay/backoffice",
      value: "uniksystem/easypay/backoffice",
      selected: false,
    },
    {
      label: "uniksystem/easypay/backoffice-leads",
      value: "uniksystem/easypay/backoffice-leads",
      selected: false,
    },
    { label: "uniksystem/admin", value: "uniksystem/admin", selected: false },
    { label: "uniksystem/about", value: "uniksystem/about", selected: false },
    { label: "uniksystem/tools", value: "uniksystem/tools", selected: false },
    {
      label: "uniksystem/account",
      value: "uniksystem/account",
      selected: false,
    },
    { label: "admin/settings", value: "admin/settings", selected: false },
    {
      label: "admin/widgetsettings",
      value: "admin/widgetsettings",
      selected: false,
    },
    { label: "admin/unikuser", value: "admin/unikuser", selected: false },
    {
      label: "admin/new-unikuser",
      value: "admin/new-unikuser",
      selected: false,
    },
    { label: "admin/role", value: "admin/role", selected: false },
    { label: "admin/role/create", value: "admin/role/create", selected: false },
    { label: "admin/permission", value: "admin/permission", selected: false },
    {
      label: "admin/permission/create",
      value: "admin/permission/create",
      selected: false,
    },
    {
      label: "admin/organization",
      value: "admin/organization",
      selected: false,
    },
    {
      label: "admin/organization/create",
      value: "admin/organization/create",
      selected: false,
    },
    {
      label: "admin/orgadmin/orgsettings",
      value: "admin/orgadmin/orgsettings",
      selected: false,
    },
    { label: "admin/report", value: "admin/report", selected: false },
    {
      label: "claimsmgt/reclamacoesAnacomPoc",
      value: "claimsmgt/reclamacoesAnacomPoc",
      selected: false,
    },
    { label: "budgetmgr/tarefas", value: "budgetmgr/tarefas", selected: false },
    {
      label: "budgetmgr/processos",
      value: "budgetmgr/processos",
      selected: false,
    },
    { label: "budgetmgr/test", value: "budgetmgr/test", selected: false },
    {
      label: "budgetmgr/workplan",
      value: "budgetmgr/workplan",
      selected: false,
    },
    {
      label: "budgetmgr/integrationcolaborators",
      value: "budgetmgr/integrationcolaborators",
      selected: false,
    },
    {
      label: "budgetmgr/categories",
      value: "budgetmgr/categories",
      selected: false,
    },
    {
      label: "budgetmgr/departments",
      value: "budgetmgr/departments",
      selected: false,
    },
    { label: "budgetmgr/teams", value: "budgetmgr/teams", selected: false },
    {
      label: "budgetmgr/partners",
      value: "budgetmgr/partners",
      selected: false,
    },
    {
      label: "budgetmgr/collaborators",
      value: "budgetmgr/collaborators",
      selected: false,
    },
    {
      label: "budgetmgr/expenses",
      value: "budgetmgr/expenses",
      selected: false,
    },
    {
      label: "budgetmgr/clientDirector",
      value: "budgetmgr/clientDirector",
      selected: false,
    },
    {
      label: "budgetmgr/auxtables",
      value: "budgetmgr/auxtables",
      selected: false,
    },
    {
      label: "budgetmgr/processos/submenu",
      value: "budgetmgr/processos/submenu",
      selected: false,
    },
    {
      label: "budgetmgr/processos/form",
      value: "budgetmgr/processos/form",
      selected: false,
    },
    {
      label: "budgetmgr/pesquisar",
      value: "budgetmgr/pesquisar",
      selected: false,
    },
    {
      label: "onboarding/integrations",
      value: "onboarding/integrations",
      selected: false,
    },
    { label: "onboarding/user", value: "onboarding/user", selected: false },
    {
      label: "onboarding/backoffic-user",
      value: "onboarding/backoffic-user",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/leads",
      value: "onboarding/backoffic-organization/leads",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/onboarding",
      value: "onboarding/backoffic-organization/onboarding",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/documents",
      value: "onboarding/backoffic-organization/documents",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/precario",
      value: "onboarding/backoffic-organization/precario",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/document-preview",
      value: "onboarding/backoffic-organization/document-preview",
      selected: false,
    },
    {
      label: "onboarding/backoffic-organization/request-new-document",
      value: "onboarding/backoffic-organization/request-new-document",
      selected: false,
    },
    {
      label: "validprocs/tarefas",
      value: "validprocs/tarefas",
      selected: false,
    },
    {
      label: "validprocs/pesquisar",
      value: "validprocs/pesquisar",
      selected: false,
    },
    {
      label: "validprocs/processos",
      value: "validprocs/processos",
      selected: false,
    },
    {
      label: "validprocs/processos/submenu",
      value: "validprocs/processos/submenu",
      selected: false,
    },
    {
      label: "validprocs/processos/form",
      value: "validprocs/processos/form",
      selected: false,
    },
    {
      label: "adatacapture/tasks",
      value: "adatacapture/tasks",
      selected: false,
    },
    {
      label: "adatacapture/processos",
      value: "adatacapture/processos",
      selected: false,
    },
    {
      label: "adatacapture/processos/submenu",
      value: "adatacapture/processos/submenu",
      selected: false,
    },
    {
      label: "adatacapture/pesquisar",
      value: "adatacapture/pesquisar",
      selected: false,
    },
    {
      label: "adatacapture/processos/form",
      value: "adatacapture/processos/form",
      selected: false,
    },
    {
      label: "adatacapture/search",
      value: "adatacapture/search",
      selected: false,
    },
    {
      label: "adatacapture/submit-document",
      value: "adatacapture/submit-document",
      selected: false,
    },
    {
      label: "adatacapture/first-upload",
      value: "adatacapture/first-upload",
      selected: false,
    },
    {
      label: "datacapture/tarefas",
      value: "datacapture/tarefas",
      selected: false,
    },
    {
      label: "datacapture/processos",
      value: "datacapture/processos",
      selected: false,
    },
    {
      label: "datacapture/processos/submenu",
      value: "datacapture/processos/submenu",
      selected: false,
    },
    {
      label: "datacapture/processos/form",
      value: "datacapture/processos/form",
      selected: false,
    },
    {
      label: "datacapture/revisaodespesas",
      value: "datacapture/revisaodespesas",
      selected: false,
    },
    {
      label: "datacapture/submissaodespesas",
      value: "datacapture/submissaodespesas",
      selected: false,
    },
    {
      label: "datacapture/onboarding",
      value: "datacapture/onboarding",
      selected: false,
    },
    {
      label: "datacapture/documentSearch",
      value: "datacapture/documentSearch",
      selected: false,
    },
    {
      label: "financeira/processos",
      value: "financeira/processos",
      selected: false,
    },
    {
      label: "financeira/processos/submenu",
      value: "financeira/processos/submenu",
      selected: false,
    },
    {
      label: "financeira/processos/form",
      value: "financeira/processos/form",
      selected: false,
    },
    { label: "gai/tarefas", value: "gai/tarefas", selected: false },
    { label: "gai/pesquisar", value: "gai/pesquisar", selected: false },
    {
      label: "gai/processos/form",
      value: "gai/processos/form",
      selected: false,
    },
    {
      label: "compliance/tarefas",
      value: "compliance/tarefas",
      selected: false,
    },
    {
      label: "compliance/processos",
      value: "compliance/processos",
      selected: false,
    },
    {
      label: "compliance/pesquisar",
      value: "compliance/pesquisar",
      selected: false,
    },
    { label: "gdpr/tarefas", value: "gdpr/tarefas", selected: false },
    { label: "gdpr/processos", value: "gdpr/processos", selected: false },
    { label: "gdpr/pesquisar", value: "gdpr/pesquisar", selected: false },
    { label: "portalrh/openai", value: "portalrh/openai", selected: false },
    { label: "portalrh/demo", value: "portalrh/demo", selected: false },
    {
      label: "portalrh/rhadmin/simuladores",
      value: "portalrh/rhadmin/simuladores",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/colaboradores",
      value: "portalrh/rhadmin/colaboradores",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/gestaoTemplates",
      value: "portalrh/rhadmin/gestaoTemplates",
      selected: false,
    },
    {
      label: "portalrh/administration/rulesRemoteWork",
      value: "portalrh/administration/rulesRemoteWork",
      selected: false,
    },
    {
      label: "portalrh/administration/rulesRemoteWork/create",
      value: "portalrh/administration/rulesRemoteWork/create",
      selected: false,
    },
    {
      label: "portalrh/administration/rulesRemoteWork/update/:id",
      value: "portalrh/administration/rulesRemoteWork/update/:id",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/exportabsence",
      value: "portalrh/rhadmin/exportabsence",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/formacao/catalogo",
      value: "portalrh/rhadmin/formacao/catalogo",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/formacao/planos",
      value: "portalrh/rhadmin/formacao/planos",
      selected: false,
    },
    {
      label: "portalrh/personalArea/myInformations",
      value: "portalrh/personalArea/myInformations",
      selected: false,
    },
    {
      label: "portalrh/personalArea/Processamento",
      value: "portalrh/personalArea/Processamento",
      selected: false,
    },
    {
      label: "portalrh/personalArea/scheduler",
      value: "portalrh/personalArea/scheduler",
      selected: false,
    },
    { label: "portalrh/expenses", value: "portalrh/expenses", selected: false },
    { label: "portalrh/reports", value: "portalrh/reports", selected: false },
    {
      label: "portalrh/personalArea/vacationsManagement",
      value: "portalrh/personalArea/vacationsManagement",
      selected: false,
    },
    {
      label: "portalrh/personalArea/vacationsManagementGroup",
      value: "portalrh/personalArea/vacationsManagementGroup",
      selected: false,
    },
    {
      label: "portalrh/siadapra/dirigentes",
      value: "portalrh/siadapra/dirigentes",
      selected: false,
    },
    {
      label: "portalrh/siadapra/dirigentes-detail/:id",
      value: "portalrh/siadapra/dirigentes-detail/:id",
      selected: false,
    },
    {
      label: "portalrh/siadapra/organizacao-detail/:id/:dropVal",
      value: "portalrh/siadapra/organizacao-detail/:id/:dropVal",
      selected: false,
    },
    {
      label: "portalrh/siadapra/organizacao",
      value: "portalrh/siadapra/organizacao",
      selected: false,
    },
    {
      label: "portalrh/personalArea/workDays",
      value: "portalrh/personalArea/workDays",
      selected: false,
    },
    {
      label: "portalrh/personalArea/bolsaHoras",
      value: "portalrh/personalArea/bolsaHoras",
      selected: false,
    },
    {
      label: "portalrh/performance/evaluator",
      value: "portalrh/performance/evaluator",
      selected: false,
    },
    {
      label: "portalrh/start/frame",
      value: "portalrh/start/frame",
      selected: false,
    },
    { label: "portalrh/start", value: "portalrh/start", selected: false },
    {
      label: "portalrh/personalarea/my-information/payment",
      value: "portalrh/personalarea/my-information/payment",
      selected: false,
    },
    {
      label: "portalrh/personalarea/document-management",
      value: "portalrh/personalarea/document-management",
      selected: false,
    },
    {
      label: "portalrh/personalarea/vacations",
      value: "portalrh/personalarea/vacations",
      selected: false,
    },
    {
      label: "portalrh/personalarea/marcacaoFerias",
      value: "portalrh/personalarea/marcacaoFerias",
      selected: false,
    },
    {
      label: "portalrh/personalarea/requests/payment",
      value: "portalrh/personalarea/requests/payment",
      selected: false,
    },
    {
      label: "portalrh/personalarea/generic",
      value: "portalrh/personalarea/generic",
      selected: false,
    },
    {
      label: "portalrh/personalarea/comprovativoPasseSocial",
      value: "portalrh/personalarea/comprovativoPasseSocial",
      selected: false,
    },
    {
      label: "portalrh/personalarea/processing",
      value: "portalrh/personalarea/processing",
      selected: false,
    },
    {
      label: "portalrh/personalarea/remoteWork",
      value: "portalrh/personalarea/remoteWork",
      selected: false,
    },
    {
      label: "portalrh/personalarea/expense",
      value: "portalrh/personalarea/expense",
      selected: false,
    },
    {
      label: "portalrh/personalarea/absenceRecord",
      value: "portalrh/personalarea/absenceRecord",
      selected: false,
    },
    {
      label: "portalrh/personalarea/extraTime",
      value: "portalrh/personalarea/extraTime",
      selected: false,
    },
    {
      label: "portalrh/personalarea/insertPresence",
      value: "portalrh/personalarea/insertPresence",
      selected: false,
    },
    {
      label: "portalrh/personalarea/pagamentosBolsa",
      value: "portalrh/personalarea/pagamentosBolsa",
      selected: false,
    },
    {
      label: "portalrh/personalarea/frame",
      value: "portalrh/personalarea/frame",
      selected: false,
    },
    {
      label: "portalrh/personalarea",
      value: "portalrh/personalarea",
      selected: false,
    },
    {
      label: "portalrh/myteam/documents",
      value: "portalrh/myteam/documents",
      selected: false,
    },
    {
      label: "portalrh/myteam/generic",
      value: "portalrh/myteam/generic",
      selected: false,
    },
    {
      label: "portalrh/myteam/comprovativoPasseSocial",
      value: "portalrh/myteam/comprovativoPasseSocial",
      selected: false,
    },
    {
      label: "portalrh/myteam/processing",
      value: "portalrh/myteam/processing",
      selected: false,
    },
    {
      label: "portalrh/myteam/expense",
      value: "portalrh/myteam/expense",
      selected: false,
    },
    {
      label: "portalrh/myteam/absenceRecord",
      value: "portalrh/myteam/absenceRecord",
      selected: false,
    },
    {
      label: "portalrh/myteam/extraTime",
      value: "portalrh/myteam/extraTime",
      selected: false,
    },
    {
      label: "portalrh/myteam/insertPresence",
      value: "portalrh/myteam/insertPresence",
      selected: false,
    },
    {
      label: "portalrh/myteam/pagamentosBolsa",
      value: "portalrh/myteam/pagamentosBolsa",
      selected: false,
    },
    {
      label: "portalrh/myteam/scheduler",
      value: "portalrh/myteam/scheduler",
      selected: false,
    },
    {
      label: "portalrh/myteam/authorizations/personalData",
      value: "portalrh/myteam/authorizations/personalData",
      selected: false,
    },
    {
      label: "portalrh/myteam/marcacaoFerias",
      value: "portalrh/myteam/marcacaoFerias",
      selected: false,
    },
    {
      label: "portalrh/myteam/remoteWork",
      value: "portalrh/myteam/remoteWork",
      selected: false,
    },
    {
      label: "portalrh/myteam/workDays",
      value: "portalrh/myteam/workDays",
      selected: false,
    },
    {
      label: "portalrh/myteam/vacationsManagement",
      value: "portalrh/myteam/vacationsManagement",
      selected: false,
    },
    {
      label: "portalrh/myteam/bolsaHoras",
      value: "portalrh/myteam/bolsaHoras",
      selected: false,
    },
    {
      label: "portalrh/myteam/frame",
      value: "portalrh/myteam/frame",
      selected: false,
    },
    { label: "portalrh/myteam", value: "portalrh/myteam", selected: false },
    {
      label: "portalrh/siadapra/frame",
      value: "portalrh/siadapra/frame",
      selected: false,
    },
    {
      label: "portalrh/siadapra/DesempenhoSiadapra",
      value: "portalrh/siadapra/DesempenhoSiadapra",
      selected: false,
    },
    { label: "portalrh/siadapra", value: "portalrh/siadapra", selected: false },
    {
      label: "portalrh/communication/frame",
      value: "portalrh/communication/frame",
      selected: false,
    },
    {
      label: "portalrh/communication",
      value: "portalrh/communication",
      selected: false,
    },
    {
      label: "portalrh/administration/frame",
      value: "portalrh/administration/frame",
      selected: false,
    },
    {
      label: "portalrh/administration",
      value: "portalrh/administration",
      selected: false,
    },
    {
      label: "portalrh/administration/exportShedules",
      value: "portalrh/administration/exportShedules",
      selected: false,
    },
    {
      label: "portalrh/administration/exportAttendance",
      value: "portalrh/administration/exportAttendance",
      selected: false,
    },
    {
      label: "portalrh/administration/parameterization/emailTemplate",
      value: "portalrh/administration/parameterization/emailTemplate",
      selected: false,
    },
    {
      label: "portalrh/administration/parameterization/annexDocs",
      value: "portalrh/administration/parameterization/annexDocs",
      selected: false,
    },
    {
      label: "portalrh/administration/parameterization/docsDisclaimer",
      value: "portalrh/administration/parameterization/docsDisclaimer",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/frame",
      value: "portalrh/rhadmin/frame",
      selected: false,
    },
    {
      label: "portalrh/rhadmin/document-management",
      value: "portalrh/rhadmin/document-management",
      selected: false,
    },
    {
      label: "portalrh/errorlogs",
      value: "portalrh/errorlogs",
      selected: false,
    },
    {
      label: "portalrh/configuration/frame",
      value: "portalrh/configuration/frame",
      selected: false,
    },
    {
      label: "portalrh/configuration",
      value: "portalrh/configuration",
      selected: false,
    },
    {
      label: "portalrh/receipts/frame",
      value: "portalrh/receipts/frame",
      selected: false,
    },
    { label: "portalrh/receipts", value: "portalrh/receipts", selected: false },
    {
      label: "portalrh/processos",
      value: "portalrh/processos",
      selected: false,
    },
    {
      label: "portalrh/processos/submenu",
      value: "portalrh/processos/submenu",
      selected: false,
    },
    {
      label: "portalrh/processos/form",
      value: "portalrh/processos/form",
      selected: false,
    },
    {
      label: "portalrh/generalComponent",
      value: "portalrh/generalComponent",
      selected: false,
    },
    { label: "portalrh/tasks", value: "portalrh/tasks", selected: false },
    { label: "rh/portal", value: "rh/portal", selected: false },
    { label: "rh/tarefas", value: "rh/tarefas", selected: false },
    { label: "rh/pesquisar", value: "rh/pesquisar", selected: false },
    { label: "rh/processos", value: "rh/processos", selected: false },
    {
      label: "rh/processos/submenu",
      value: "rh/processos/submenu",
      selected: false,
    },
    { label: "rh/processos/form", value: "rh/processos/form", selected: false },
    { label: "bpm/tarefas", value: "bpm/tarefas", selected: false },
    { label: "bpm/processos", value: "bpm/processos", selected: false },
    {
      label: "bpm/processos/submenu",
      value: "bpm/processos/submenu",
      selected: false,
    },
    {
      label: "bpm/processos/form",
      value: "bpm/processos/form",
      selected: false,
    },
    { label: "bpm/pesquisar", value: "bpm/pesquisar", selected: false },
    { label: "bpm/delegacoes", value: "bpm/delegacoes", selected: false },
    { label: "bpm/admin", value: "bpm/admin", selected: false },
  ];
};