import React, { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { closeSideBar } from "~/store/ducks/applicationMenu/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import "~/assets/css/styles.css";

const ContentContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (e) => {
    e.stopPropagation();
    //e.preventDefault();
    if (window.innerWidth < 992) dispatch(closeSideBar());
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      className="scrollBar-visible"
      style={
        isMobile
          ? {}
          : { width: "100%", overflowY: "auto", maxHeight: "100vh" }
      }
    >
      {children}
    </div>
  );
};

export default ContentContainer;
