import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getInformationProcessMarcacaoFerias,
  approveDeleteRemoveMarcacaoFerias,
} from "~/store/ducks/portalrh/actionTypes";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Spinner from "~/components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import "./modalCss.css";
import {
  FaCheckCircle,
  FaClock,
  FaTimesCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { format, parseISO } from "date-fns";
import { Stack, Box } from "@mui/material";

const ModalMarcacaoFerias = ({
  t,
  process,
  closeButton,
  showModalMarcacaoFerias,
  handleCloseModalMarcacaoFerias,
  setmakeUpdate,
  styleSheet,
  searchFilter,
}) => {
  const {
    infoProcessMarcacaoFerias,
    isLoadingInfoProcMarcacaoFerias,
    collaborators,
  } = useSelector((state) => state.portalrhReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { genericRequestState } = useSelector((state) => state.portalrhReducer);

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [infoData, setinfoData] = useState({});
  const [transformedData, setTransformedData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (process != undefined) {
      const payload = {
        id: process.id,
      };
      dispatch(getInformationProcessMarcacaoFerias(payload));
    }
  }, [process]);

  useEffect(() => {
    setinfoData(infoProcessMarcacaoFerias);
  }, [infoProcessMarcacaoFerias]);

  const buildApprovedColumn = (_, row, index) => {
    if (row.aprovado === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (row.aprovado === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (row.aprovado === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (row.aprovado === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (row.aprovado === 5) {
      return t("portalrh.workDays.Visto");
    }
  };
  function dateFormater(cell, row) {
    if (row.date) {
      const parsedDate = parseISO(cell);
      return (
        format(parsedDate, "dd/MM/yyyy") +
        " - " +
        (infoData.dates && infoData.dates.meioDia
          ? t("portalrh.workDays.diasMarcadosMeiodia")
          : t("portalrh.workDays.diaInteiro"))
      );
    }
  }
  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "date",
      text: t("portalrh.requestTable.date"),
      sort: true,
      formatter: dateFormater,
    },
  ];

  const handleCancelRequest = async () => {
    const payload = {
      ped_id: process.id,
      state: 2,
      username: userNamePortalRH.username,
    };
    await dispatch(approveDeleteRemoveMarcacaoFerias(payload));

    setTimeout(() => {
      setmakeUpdate(true);
      handleCloseModalMarcacaoFerias(searchFilter, 1, true);
    }, 200);
  };

  const { SearchBar } = Search;
  useEffect(() => {
    if (infoData?.dates !== undefined) {
      const transformedDataTemp = infoData.dates.dates.map((date) => ({
        date,
      }));
      setTransformedData(transformedDataTemp);
    }
  }, [infoData]);

  return (
    <>
      {Object.keys(infoData).length > 0 &&
      Object.keys(transformedData).length > 0 ? (
        <>
          <Modal
            show={showModalMarcacaoFerias}
            dialogClassName={"modal-tarefa-50w custom-modal"}
            centered
            backdrop="static"
            onHide={() => handleCloseModalMarcacaoFerias(searchFilter, 1, true)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {infoData?.request && infoData?.request[0].type === "Novo"
                  ? t("portalrh.workDays.feriasModal")
                  : t("portalrh.workDays.feriasModalCancel")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={
                  styleSheet?.processScroll ? "scrollBar-visibleModal" : ""
                }
              >
                <div style={{ margin: isMobile ? "0px":  "20px 70px" }}>
                  <div className="custom-table custom-table-small tableDateInformation scrollBar-tableDateInformation">
                    <ToolkitProvider
                      keyField="id"
                      data={!isLoadingInfoProcMarcacaoFerias && transformedData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div
                            style={{ paddingBottom: "20px", display: "flex" }}
                          >
                            {infoData?.dates.length > 1 && (
                              <SearchBar
                                {...props.searchProps}
                                placeholder={t("Search")}
                              />
                            )}
                          </div>
                          <Spinner
                            spinning={isLoadingInfoProcMarcacaoFerias}
                            wrapper
                          />
                          {!isLoadingInfoProcMarcacaoFerias && (
                            <>
                              <h6>{t("portalrh.workDays.diasSolicitados")}</h6>
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                //pagination={paginationFactory(pagination)}
                                noDataIndication={t(
                                  "portalrh.personalData.noRequests"
                                )}
                                striped
                                hover
                              />
                              {genericRequestState === "Pendente" && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "0px 10px",
                                  }}
                                >
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleCloseModalMarcacaoFerias(
                                          searchFilter,
                                          1,
                                          true
                                        )
                                      }
                                    >
                                      {t("portalrh.workDays.back")}
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() => handleCancelRequest()}
                                    >
                                      {t("portalrh.workDays.cancelRequest")}
                                    </Button>
                                  </Stack>
                                </Box>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
                <div
                  style={{ margin: isMobile ? "0px":  "20px 70px" }}
                  className="scrollBar-autorizersDateInformation"
                >
                  <h6>{t("portalrh.workDays.listaAprovadores")}</h6>
                  <ListGroup>
                    {!isLoadingInfoProcMarcacaoFerias &&
                      infoData?.autorizacao.map((item, index) => (
                        <>
                          {infoData.request[0].state_id === 3 ? (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                <>
                                  {item.username}
                                  {item.date !== null ? ` - ${item.date}` : ""}
                                </>

                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {t(`portalrh.workDays.Aprovado`)}
                                    </Tooltip>
                                  }
                                  placement="right"
                                >
                                  {item.state_id === "3" ? (
                                    <FaCheckCircle
                                      style={{
                                        color: "#008000",
                                      }}
                                    />
                                  ) : (
                                    <FaRegCircleCheck
                                      style={{
                                        color: "#008000",
                                      }}
                                    />
                                  )}
                                </OverlayTrigger>
                              </span>
                            </ListGroup.Item>
                          ) : infoData.request[0].state_id === 4 ? (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                <>
                                  {item.username}
                                  {item.date !== null ? ` - ${item.date}` : ""}
                                </>

                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {t(`portalrh.workDays.Recusado`)}
                                    </Tooltip>
                                  }
                                  placement="right"
                                >
                                  {item.state_id === "4" ? (
                                    <FaTimesCircle
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    />
                                  ) : (
                                    <FaRegTimesCircle
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    />
                                  )}
                                </OverlayTrigger>
                              </span>
                            </ListGroup.Item>
                          ) : (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                <>
                                  {item.username}
                                  {item.date !== null ? ` - ${item.date}` : ""}
                                </>
                                {item.state === "Pendente" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {t(`portalrh.workDays.${item.state}`)}
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <FaClock style={{ color: "#FFA500" }} />
                                  </OverlayTrigger>
                                )}
                                {item.state === "Aprovado" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {t(`portalrh.workDays.${item.state}`)}
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <FaCheckCircle
                                      style={{
                                        color: "#008000",
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                                {item.state === "Rejeitado" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {t(`portalrh.workDays.${item.state}`)}
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <FaTimesCircle
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                                {item.state === "Recusado" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {t(`portalrh.workDays.${item.state}`)}
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <FaTimesCircle
                                      style={{
                                        color: "#FF0000",
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                              </span>
                            </ListGroup.Item>
                          )}
                        </>
                      ))}
                  </ListGroup>
                </div>
                {/* {infoData?.trabalhoremoto.length > 0 &&
                  infoData?.trabalhoremoto[0].comentario && (
                    <Alert key="danger" variant="danger">
                      <b>{t("portalrh.workDays.refuseMessage")}:</b>{" "}
                      {infoData?.trabalhoremoto[0].comentario}
                    </Alert>
                  )} */}
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingInfoProcMarcacaoFerias} wrapper={true} />
      )}
    </>
  );
};
export default withNamespaces()(ModalMarcacaoFerias);
