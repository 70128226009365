import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
  getGraphInfoWithDate,
  getGraphSpecificGroup,
  getGraphSpecificOperation,
  getGraphAllGroups,
  getGraphAllOperations,
} from "~/store/ducks/graphs/actionTypes";
import Chart from "chart.js";
import StatisticsPeriodGroupFilters from "./StatisticsPeriodGroupFilters";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";

const StatisticsConcluedTasks = ({t}) => {
  const dispatch = useDispatch();
  const refStatisticsConcluedTasks = useRef(null);
  const chartInstance = useRef(null);

  const { graphInfo, graphGroups, graphOperations } = useSelector(
    (state) => state.graphsReducer
  );
  const [cleanGroupDropdown, setCleanGroupDropdown] = useState(false);
  const [cleanOperationDropdown, setCleanOperationDropdown] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("");
  const [formattedGraphGroups, setFormattedGraphGroups] = useState([]);
  const [formattedGraphOperations, setFormattedGraphOperations] = useState([]);
  const [dateStartEnd, setDateStartEnd] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  useEffect(() => {
    dispatch(getGraphAllGroups());
    dispatch(getGraphAllOperations());
    executeGraph();
  }, []);

  useEffect(() => {
    const formattedGroups = graphGroups.map((group) => ({
      label: group.designacao,
      value: group.id.toString(),
      selected: group.id.toString() === selectedGroup,
    }));

    const formattedOperations = graphOperations.map((operation) => ({
      label: t(`portalrh.operations.${operation.name}`),
      value: operation.id.toString(),
      selected: operation.id.toString() === selectedOperation,
    }));

    setFormattedGraphGroups(formattedGroups);
    setFormattedGraphOperations(formattedOperations);
  }, [graphGroups, graphOperations, selectedGroup, selectedOperation]);

  useEffect(() => {
    if (Object.keys(graphInfo).length > 0) {
      const myChartRef = refStatisticsConcluedTasks.current.getContext("2d");
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const chartConfig = {
        ...graphInfo,
        plugins: [ChartDataLabels],
      };
      chartInstance.current = new Chart(myChartRef, chartConfig);
    }
  }, [graphInfo]);

  const executeGraph = (
    startAt = "",
    endAt = "",
    group = "",
    operation = ""
  ) => {
    const formatDate = (dateString) => {
      const date = moment(dateString);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    };
  
    const payload = {
      startAt: formatDate(startAt),
      endAt: formatDate(endAt),
      group,
      operation,
      urlRequest: "get-finished-requests",
    };
    dispatch(getGraphInfoWithDate(payload));
  };

  const handleChangeGroup = (e) => {
    const { startAt, endAt } = dateStartEnd[0];
    setSelectedGroup(e);
    setSelectedOperation("");
    setCleanOperationDropdown(true);
    dispatch(getGraphSpecificOperation(e));
    executeGraph(startAt, endAt, e, selectedOperation);
  };

  const handleChangeOperation = (e) => {
    setSelectedOperation(e);
    const { startAt, endAt } = dateStartEnd[0];
    if (selectedGroup == "") {
      dispatch(getGraphSpecificGroup(e));
    }
    executeGraph(startAt, endAt, selectedGroup, e);
  };

  const handleCleanStates = () => {
    setSelectedGroup("");
    setSelectedOperation("");
    setCleanGroupDropdown(true);
    setCleanOperationDropdown(true);
    setDateStartEnd([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    executeGraph();
  };

  const handleChangeRange = (range) => {
    setDateStartEnd([range.selection]);
    executeGraph(
      range.selection.startDate,
      range.selection.endDate,
      selectedGroup,
      selectedOperation
    );
  };

  return (
    <Row>
      <Col xs={12} md={4} lg={4} style={{ padding: "10px" }}>
        <StatisticsPeriodGroupFilters
          handleChangeGroup={handleChangeGroup}
          handleChangeOperation={handleChangeOperation}
          renderGroups={formattedGraphGroups}
          renderOperations={formattedGraphOperations}
          cleanGroupDropdown={cleanGroupDropdown}
          setCleanGroupDropdown={setCleanGroupDropdown}
          cleanOperationDropdown={cleanOperationDropdown}
          setCleanOperationDropdown={setCleanOperationDropdown}
          selectedGroup={selectedGroup}
          selectedOperation={selectedOperation}
          cleanStates={handleCleanStates}
          handleChangeRange={handleChangeRange}
          dateStartEnd={dateStartEnd}
        />
      </Col>
      <Col
        xs={12}
        md={4}
        lg={4}
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "100%", width: "auto" }}>
          <canvas
            id="myAreaChart"
            ref={refStatisticsConcluedTasks}
            width={650}
            height={280}
          ></canvas>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(StatisticsConcluedTasks);
