import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getGraphInfo,
  getGraphSpecificGroup,
  getGraphSpecificOperation,
  getGraphAllGroups,
  getGraphAllOperations,
} from "~/store/ducks/graphs/actionTypes";
import { Row, Col } from "react-bootstrap";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import StatisticsGroupFilters from "./StatisticsGroupFilters";

const StatisticsOpenTasks = ({ t }) => {
  const dispatch = useDispatch();
  const refStatisticsOpenTasks = useRef(null);
  const chartInstance = useRef(null); // Add a ref to store the chart instance

  const [cleanGroupDropdown, setCleanGroupDropdown] = useState(false);
  const [cleanOperationDropdown, setCleanOperationDropdown] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("");
  const [formattedGraphGroups, setFormattedGraphGroups] = useState([]);
  const [formattedGraphOperations, setFormattedGraphOperations] = useState([]);

  const { graphInfo, graphGroups, graphOperations } = useSelector(
    (state) => state.graphsReducer
  );

  useEffect(() => {
    dispatch(getGraphAllGroups());
    dispatch(getGraphAllOperations());
    executeGraph();
  }, []);

  useEffect(() => {
    const formattedGroups = graphGroups.map((group) => ({
      label: group.designacao,
      value: group.id.toString(),
      selected: group.id.toString() === selectedGroup,
    }));

    const formattedOperations = graphOperations.map((operation) => ({
      label: t(`portalrh.operations.${operation.name}`),
      value: operation.id.toString(),
      selected: operation.id.toString() === selectedOperation,
    }));

    setFormattedGraphGroups(formattedGroups);
    setFormattedGraphOperations(formattedOperations);
  }, [graphGroups, graphOperations, selectedGroup, selectedOperation]);

  useEffect(() => {
    if (Object.keys(graphInfo).length > 0) {
      const myChartRef = refStatisticsOpenTasks.current.getContext("2d");
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const chartConfig = {
        ...graphInfo,
        plugins: [ChartDataLabels],
      };
      chartInstance.current = new Chart(myChartRef, chartConfig);
    }
  }, [graphInfo]);

  const executeGraph = (group = "", operation = "") => {
    const payload = {
      group,
      operation,
    };
    dispatch(getGraphInfo(payload));
  };

  const handleChangeGroup = (e) => {
    setSelectedGroup(e);
    setSelectedOperation("");
    setCleanOperationDropdown(true);
    dispatch(getGraphSpecificOperation(e));
    executeGraph(e, selectedOperation);
  };

  const handleChangeOperation = (e) => {
    setSelectedOperation(e);
    if (selectedGroup == "") {
      dispatch(getGraphSpecificGroup(e));
    }
    executeGraph(selectedGroup, e);
  };

  const handleCleanStates = () => {
    setSelectedGroup("");
    setSelectedOperation("");
    setCleanGroupDropdown(true);
    setCleanOperationDropdown(true);
    executeGraph();
  };

  return (
    <Row>
      <Col xs={12} md={4} lg={4} style={{ padding: "10px" }}>
        <StatisticsGroupFilters
          handleChangeGroup={handleChangeGroup}
          handleChangeOperation={handleChangeOperation}
          renderGroups={formattedGraphGroups}
          renderOperations={formattedGraphOperations}
          cleanGroupDropdown={cleanGroupDropdown}
          setCleanGroupDropdown={setCleanGroupDropdown}
          cleanOperationDropdown={cleanOperationDropdown}
          setCleanOperationDropdown={setCleanOperationDropdown}
          selectedGroup={selectedGroup}
          selectedOperation={selectedOperation}
          cleanStates={handleCleanStates}
        />
      </Col>
      <Col
        xs={12}
        md={4}
        lg={4}
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "100%", width: "auto" }}>
          <canvas
            id="myAreaChart"
            ref={refStatisticsOpenTasks}
            width={650}
            height={280}
          ></canvas>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(StatisticsOpenTasks);
