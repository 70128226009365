export const GET_GRAPH_INFO = "graphs@GET_GRAPH_INFO";
export const SET_GRAPH_INFO = "graphs@SET_GRAPH_INFO";
export const GET_GRAPH_ALL_OPERATIONS = "graphs@GET_GRAPH_ALL_OPERATIONS";
export const GET_GRAPH_ALL_GROUPS = "graphs@GET_GRAPH_ALL_GROUPS";
export const SET_GRAPH_OPERATIONS = "graphs@SET_GRAPH_OPERATIONS";
export const SET_GRAPH_GROUPS = "graphs@SET_GRAPH_GROUPS";
export const GET_GRAPH_SPECIFIC_GROUP = "graphs@GET_GRAPH_SPECIFIC_GROUP";
export const GET_GRAPH_SPECIFIC_OPERATION =
  "graphs@GET_GRAPH_SPECIFIC_OPERATION";
export const GET_GRAPH_INFO_WITH_DATES = "graphs@GET_GRAPH_INFO_WITH_DATES";

export const getGraphInfo = (payload) => {
  return {
    type: GET_GRAPH_INFO,
    payload,
  };
};

export const getGraphInfoWithDate = (payload) => {
  return {
    type: GET_GRAPH_INFO_WITH_DATES,
    payload,
  };
};

export const setGraphInfo = (graphInfo) => {
  return {
    type: SET_GRAPH_INFO,
    graphInfo,
  };
};

export const getGraphAllOperations = () => {
  return {
    type: GET_GRAPH_ALL_OPERATIONS,
  };
};

export const setGraphOperations = (graphOperations) => {
  return {
    type: SET_GRAPH_OPERATIONS,
    graphOperations,
  };
};

export const getGraphAllGroups = () => {
  return {
    type: GET_GRAPH_ALL_GROUPS,
  };
};

export const setGraphGroups = (graphGroups) => {
  return {
    type: SET_GRAPH_GROUPS,
    graphGroups,
  };
};

export const getGraphSpecificGroup = (id) => {
  return {
    type: GET_GRAPH_SPECIFIC_GROUP,
    id,
  };
};

export const getGraphSpecificOperation = (id) => {
  return {
    type: GET_GRAPH_SPECIFIC_OPERATION,
    id,
  };
};
