import { reject } from "q";
import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import { BPM_MIDDLEWARE } from "~/utils/constants";

import { setSimpleUserCreated } from "~/pages/Login/actions";
import {
  successRequestUsers,
  failRequestUsers,
  successAddUser,
  failAddUser,
  successDeleteUser,
  failDeleteUser,
  successUpdateUser,
  failUpdateUser,
  setLegacyApps,
  setLegacyUsers,
  setUnikUser,
  setRoles,
  setPulledLegacyUsers,
  sucessExtendedAttributes,
  setAssumeCheckout,
  setAssumeCheckin,
} from "../actions";

import {
  successRequestUser,
  failRequestUser,
} from "~/containers/Layout/actions";

import { activeSuccess, activeError } from "~/components/Messages/actions";

export function* loadLegacyApps() {
  try {
    const resp = yield http.get("/api/legacy-application/read");
    yield put(setLegacyApps(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* loadLegacyUsers({ data }) {
  try {
    const resp = yield http.get(`/api/legacy-user/read?application=${data}`);
    yield put(setLegacyUsers(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* addUser({ data }) {
  const unikUserJson = JSON.stringify(data);
  const unikUserBlob = new Blob([unikUserJson], {
    type: "application/json",
  });

  const _data = new FormData();
  _data.append("picture", data.detail.picture);
  _data.append("unikUser", unikUserBlob);

  try {
    yield http.post("/api/user/create", _data);
    yield put(successAddUser());
    yield put(activeSuccess("admin.userManagement.persist.successMessage"));
  } catch (error) {
    const { response } = error;
    yield put(failAddUser());
    yield put(
      activeError(error?.response?.data?.message || error?.response?.statusText)
    );
  }
}

export function* updateUser({ data }) {
  const unikUserJson = JSON.stringify(data);
  const unikUserBlob = new Blob([unikUserJson], {
    type: "application/json",
  });
  const _data = new FormData();
  _data.append("picture", data.detail.picture);
  _data.append("unikUser", unikUserBlob);

  try {
    const resp = yield http.put("/api/user/update", _data);
    yield put(successUpdateUser(resp.data));
    yield put(
      activeSuccess("admin.userManagement.persist.updateSuccessMessage")
    );
    if (resp.status === 200) {
      let response = yield http.get("/api/user/me");
      yield put(successRequestUser(response.data));
    }
  } catch (error) {
    yield put(failUpdateUser());
    yield put(activeError("admin.userManagement.persist.errorPersistMessage"));
    reject(error);
  }
}

export function* requestUsers({ data }) {
  try {
    const resp = yield http.get(`/api/user/read?username=${data.username}`);
    yield put(successRequestUsers(resp.data));
  } catch (error) {
    yield put(failRequestUsers());
    reject(error);
  }
}

export function* assumeCheckOut({ data }) {
  try {
    yield http.post(`/api/user/checkout`, data);
    yield put(setAssumeCheckout("success"));
  } catch (error) {
    yield put(setAssumeCheckout("error"));
    reject(error);
  } finally {
    yield call(requestUsers, { data: { username: "" } });
  }
}

export function* assumeCheckIn({ data }) {
  try {
    yield http.post(`/api/user/checkin?userId=${data.userId}`);
    yield put(setAssumeCheckin("success"));
  } catch (error) {
    yield put(setAssumeCheckin("error"));
    reject(error);
  } finally {
    yield call(requestUsers, { data: { username: "" } });
  }
}

export function* updateAccessCheckout({ data }) {
  try {
    yield http.put(`/api/user/update-hour-access`, data);
  } catch (error) {
    reject(error);
  }
}

export function* deleteUser({ data }) {
  try {
    const resp = yield http.delete(`/api/user/delete/${data.id}`);
    yield put(successDeleteUser(resp.data));
  } catch (error) {
    yield put(failDeleteUser());
    reject(error);
  }
}

export function* loadUnikUser({ id }) {
  try {
    const resp = yield http.get(`/api/user/${id}`);
    yield put(setUnikUser(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* requestAllRoles() {
  try {
    const resp = yield http.get("/api/role/all");
    yield put(setRoles(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* requestAllExtendedAttributes({ data }) {
  try {
    const resp = yield http.get(`/api/external-parameters/${data}`);
    yield put(sucessExtendedAttributes(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* createSimpleUser({ data }) {
  try {
    yield put(setSimpleUserCreated(false));
    yield http.post("/api/user/simple/create", data);
    yield put(successAddUser());
    yield put(setSimpleUserCreated(true));
  } catch (error) {
    yield put(failAddUser());
    reject(error);
  }
}

export function* pullLegacyUsersOnlyApp({ appName }) {
  try {
    const resp = yield http.get(
      `/api/middleware/pull-application-only?application=${appName}`,
      BPM_MIDDLEWARE
    );
    yield put(setPulledLegacyUsers(resp.data));
  } catch (error) {
    yield put(setPulledLegacyUsers(null));
    reject(error);
  }
}

export function* pullLegacyUsers() {
  try {
    const resp = yield http.get(
      "/api/middleware/pull-applications",
      BPM_MIDDLEWARE
    );
    yield put(setPulledLegacyUsers(resp.data));
  } catch (error) {
    yield put(setPulledLegacyUsers(null));
    reject(error);
  }
}

export function* updateUserSettingsADatacapture({ data }) {
  const unikUserJson = JSON.stringify(data);
  const unikUserBlob = new Blob([unikUserJson], {
    type: "application/json",
  });
  const _data = new FormData();
  _data.append("unikUser", unikUserBlob);
  try {
    let resp = yield http.put("api/user/settings/update", _data);
    if (resp.status === 200) {
      resp = yield http.get("/api/user/me");
      yield put(successRequestUser(resp.data));
    }
  } catch (error) {
    yield put(failRequestUser());
    reject(error);
  }
}

export function* removeUserPicture({ id }) {
  try {
    let resp = yield http.post(`/api/user/remove-picture/${id}`);
    yield put(activeSuccess("admin.userManagement.persist.pictureMessage"));
    if (resp.status === 200) {
      resp = yield http.get("/api/user/me");
      yield put(successRequestUser(resp.data));
    }
  } catch (error) {
    yield put(activeError("admin.userManagement.persist.errorPicture"));
  }
}
