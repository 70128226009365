import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { Form, Button } from "react-bootstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import { withNamespaces } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { setGraphInfo } from "~/store/ducks/graphs/actionTypes";

const StatisticsPeriodGroupFilters = ({
  handleChangeGroup,
  handleChangeOperation,
  renderGroups,
  renderOperations,
  cleanGroupDropdown,
  setCleanGroupDropdown,
  cleanOperationDropdown,
  setCleanOperationDropdown,
  selectedGroup,
  selectedOperation,
  cleanStates,
  handleChangeRange,
  dateStartEnd,
  t,
}) => {
  const dispatch = useDispatch();

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const [locale, setLocale] = React.useState("en");

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      setLocale(userSettings.language.trim());
    }
  }, [userSettings]);

  return (
    <>
      <Form.Row style={{ marginTop: "0" }}>
        <Form.Label style={{ marginRight: "10px" }}>Grupos</Form.Label>
        <Dropdown
          list={renderGroups}
          handleSubmit={handleChangeGroup}
          emptyDefault={true}
          resetDropdown={cleanGroupDropdown}
          setResetDropdown={setCleanGroupDropdown}
        />
      </Form.Row>

      <Form.Row style={{ marginTop: "0" }}>
        <Form.Label style={{ marginRight: "10px" }}>Operação</Form.Label>
        <Dropdown
          list={renderOperations}
          handleSubmit={handleChangeOperation}
          emptyDefault={true}
          resetDropdown={cleanOperationDropdown}
          setResetDropdown={setCleanOperationDropdown}
        />
      </Form.Row>
      <Form.Row style={{ marginTop: "0" }}>
        <DateRange
          locale={locales[locale]}
          editableDateInputs={false}
          onChange={handleChangeRange}
          moveRangeOnFirstSelection={false}
          ranges={dateStartEnd}
        />
      </Form.Row>
      <Form.Row style={{ marginTop: "0" }}>
        <div
          style={{
            width: "100%",
            justifyContent: "right",
            display: "flex",
            gap: "1rem",
          }}
        >
          {(selectedGroup !== "" || selectedOperation !== "") && (
            <div className="divForTrashStatistics" onClick={cleanStates}>
              <FaTrash />
            </div>
          )}
        </div>
      </Form.Row>
    </>
  );
};

export default withNamespaces()(StatisticsPeriodGroupFilters);
