import React from "react";
import { withNamespaces } from "react-i18next";
import StatisticsPendingTasks from "~/pages/Statistics/StatisticsPendingTasks";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { Card } from "react-bootstrap";

const KPITarefasPendentes = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  index,
  t,
  ...props
}) => {
  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            KPI Pendente
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ padding: "10px" }}>
            <StatisticsPendingTasks />
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(KPITarefasPendentes);
