import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FilterToogle from "./FilterToogle";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pagination } from "~/components/pagination/currentSizePerPage/5";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  getReportToExtract,
  buttonBackendCall,
  setSuccessDownloadReportExtract,
} from "~/store/ducks/reports/actionTypes";
import Statistics from "~/pages/Statistics";
import Toast from "react-bootstrap/Toast";
import {
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import Filters from "./Filters";
import { withNamespaces } from "react-i18next";

const CardReports = ({
  tableInfo,
  columnsToAdd,
  filtersToAdd,
  titleToCard,
  isMobile,
  filterState,
  setFilterState,
  checkboxes,
  setCheckboxes,
  selectAll,
  setSelectAll,
  listToExport,
  setListToExport,
  translations,
  selectedItem,
  isNotTable,
  t,
}) => {
  const dispatch = useDispatch();
  const { isLoadingReports, reportDownloadSuccess } = useSelector(
    (state) => state.reportReducer
  );
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let language = userSettings?.language ? userSettings.language : "pt";

  const [activeKeys, setActiveKeys] = useState({
    filter: "0",
    categories: null,
  });
  const [currentList, setCurrentList] = useState(tableInfo);
  const [alertMessage, setalertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [openAlert, setopenAlert] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(setSuccessDownloadReportExtract(false));
    setopenAlert(false);
    setalertMessage("");
    setAlertType("");
    setActiveKeys({
      filter: "0",
      categories: null,
    });
  }, []);

  useEffect(() => {
    if (reportDownloadSuccess) {
      setAlertType("success");
      setalertMessage(t("portalrh.reports.exportSucess"));
      setopenAlert(true);
    } else {
      setAlertType("");
      setalertMessage("");
      setopenAlert(false);
    }
  }, [reportDownloadSuccess]);

  useEffect(() => {
    setCurrentList(tableInfo);
  }, [tableInfo]);

  const handleActiveKey = (name) => {
    let activeKeys_ = { ...activeKeys };
    activeKeys_[name] = activeKeys[name] === null ? "0" : null;
    setActiveKeys(activeKeys_);
  };

  const handleCallBackendEndPoint = (method, url) => {
    dispatch(buttonBackendCall(method, url));
  };

  const handleSearch = (download = false) => {
    if (!download) {
      for (let [key, filter] of Object.entries(filtersToAdd)) {
        if (!filter.obligatory) continue;
        if (!(key in filterState)) {
          setalertMessage(t("portalrh.reports.fillAllRequiredFields"));
          setopenAlert(true);
          setAlertType("warning");
          return;
        }
      }
    }
    let payload = { ...filterState };
    for (let [key, value] of Object.entries(payload)) {
      if (value instanceof Date) {
        let day = String(value.getDate()).padStart(2, "0");
        let month = String(value.getMonth() + 1).padStart(2, "0");
        let year = value.getFullYear();

        payload[key] = `${year}-${month}-${day}`;
      }
    }
    const selectedItemKey = Object.keys(selectedItem)[0];
    dispatch(getReportToExtract(payload, selectedItemKey, download));
  };

  const formatDate = (cell, row, rowIndex, formatExtraData) => {
    if (cell) {
      const date = moment(cell, "YYYY-MM-DD HH:mm:ss.S").format("DD-MM-YYYY");

      return date;
    }
  };
  const checkToExport = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form.Check
        type="checkbox"
        label=""
        id={rowIndex}
        checked={formatExtraData.value[row.id] || false}
        onChange={(e) => handleCheckboxChange(e, row, formatExtraData)}
      />
    );
  };
  const handleAllCheckboxesChange = (event) => {
    setSelectAll(event.target.checked);
    setCheckboxes(
      currentList.reduce((result, row) => {
        result[row.id] = event.target.checked;
        return result;
      }, {})
    );
  };
  const handleCheckboxChange = (e, row, formatExtraData) => {
    setCheckboxes({
      ...checkboxes,
      [row.id]: e.target.checked,
    });
  };

  useEffect(() => {
    let trueKeys = [];

    if (Object.keys(checkboxes).length > 0) {
      for (const key in checkboxes) {
        if (checkboxes[key]) {
          trueKeys.push(Number(key));
        } else if (!checkboxes[key]) {
          trueKeys = trueKeys.filter((e) => e !== false);
        }
      }
    }

    if (currentList.length > 0 && trueKeys.length > 0) {
      let newListToExport = [];
      trueKeys.forEach((key) => {
        currentList.forEach((row) => {
          if (key === +row.id) {
            newListToExport.push(row);
          }
        });
      });
      setListToExport(newListToExport);
    }
  }, [checkboxes, currentList]);

  function exportTxtFunction() {
    handleSearch(true);
  }

  useEffect(() => {
    if (columnsToAdd.length > 0) {
      const commonStyles = { width: "fit-content", textAlign: "center" };
      const lingua = language.trim();

      const formattedColumns = columnsToAdd.map((column) => ({
        ...column,
        text:
          translations[lingua] && translations[lingua][column.text]
            ? translations[lingua][column.text]
            : column.text,
        headerStyle: commonStyles,
        style: commonStyles,
        formatter: column.type === "date" ? formatDate : undefined,
      }));

      if (isMobile) {
        const mobileColumns = [
          {
            dataField: "id",
            hidden: true,
          },
          {
            dataField: "mobileCard",
            text: "",
            formatter: getMobileCardFormatter,
            formatExtraData: {
              headers: formattedColumns,
            },
            hidden: false,
            headerStyle: { display: "none" },
            style: { padding: "0px" },
          },
        ];
        setColumns(mobileColumns);
      } else {
        setColumns([...formattedColumns]);
      }
    }
  }, [columnsToAdd, language, translations, isMobile]);

  const getMobileCardFormatter = (cell, row, rowIndex, formatExtraData) => {
    const headers = formatExtraData.headers;
    return (
      <div>
        <br />
        {headers.map((header, index) => {
          const key = header.dataField;
          if (key && row[key] !== undefined) {
            const value =
              typeof row[key] === "boolean" ? row[key].toString() : row[key];
            return (
              <div key={index}>
                <b>{header.text}: </b>
                {value}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenAlert(false);
    setalertMessage("");
    setAlertType("");
    dispatch(setSuccessDownloadReportExtract(false));
  };

  const { SearchBar } = Search;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingReports}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseWarning}
      >
        <Alert
          onClose={handleCloseWarning}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header>
            <h6>{`${t("portalrh.reports.reports")} ${titleToCard}`}</h6>
          </Card.Header>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", padding: "0px" }}
          >
            <div
              style={{
                margin: "0px 0px 2rem",
                width: "100%",
                alignSelf: isMobile ? "auto" : "center",
              }}
            >
              {!isNotTable && (
                <>
                  {" "}
                  {filtersToAdd !== null && (
                    <>
                      <FilterToogle
                        handleActiveKey={handleActiveKey}
                        activeKeys={activeKeys}
                      />
                      <Filters
                        filtersToAdd={filtersToAdd}
                        activeKeys={activeKeys}
                        setActiveKeys={setActiveKeys}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        handleSearch={handleSearch}
                        translations={translations}
                        selectedItem={selectedItem}
                        setCurrentList={setCurrentList}
                        handleCallBackendEndPoint={handleCallBackendEndPoint}
                      />
                    </>
                  )}
                  <div className="custom-table withoutOverflow">
                    {columns.length > 0 && (
                      <ToolkitProvider
                        keyField="id"
                        data={currentList}
                        columns={columns}
                        search={{ searchFormatted: true }}
                      >
                        {(props) => (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "nowrap",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5rem",
                                  marginLeft: "10px",
                                  display: "flex",
                                  paddingTop: "50px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {true && filtersToAdd !== null && (
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder={t("general.search")}
                                  />
                                )}
                                <div style={{ paddingLeft: "15px" }} />
                              </div>
                            </div>
                            <BootstrapTable
                              {...props.baseProps}
                              id="document-table"
                              responsive
                              bootstrap4
                              pagination={paginationFactory(pagination)}
                              noDataIndication={t(
                                "datacapture.general.dataNotFound"
                              )}
                              //rowEvents={rowEvents}
                              pageSize={5}
                              hover
                              striped
                            />
                            <BootstrapTable
                              {...props.baseProps}
                              id="document-table-exportabsence"
                              responsive
                              bootstrap4
                              noDataIndication={t(
                                "datacapture.general.dataNotFound"
                              )}
                              hover
                              striped
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      className="card-button"
                      onClick={() => {
                        exportTxtFunction();
                      }}
                      style={{ marginLeft: "15px" }}
                    >
                      {t("portalrh.abscenses.exportar")}
                    </Button>
                  </div>
                </>
              )}
              {isNotTable && (
                <Statistics
                  selectedItem={selectedItem}
                  t={t}
                  translations={translations}
                  isMobile={isMobile}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(CardReports);
