import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";
import {
  getRequestsByUsernameWithType,
  setGenericRequestState,
  setapproveDeleteRemoveDayRemoteWork,
  setapproveDeleteRemoveMarcacaoFerias,
} from "~/store/ducks/portalrh/actionTypes";
import { Tabs, Tab } from "react-bootstrap";
import { RequestState } from "~/components/widgets/Requests/TabsEnum.js";

const PersonalAreaRequestTable = ({
  searchFilter,
  t,
  handleOpenFlowModal,
  handleOpenModalRemoteWork,
  handleOpenModalMarcacaoFerias,
  makeUpdate,
  setmakeUpdate,
  setmessageToast,
  callGetRequestsByUsername,
}) => {
  const {
    genericRequestState,
    infoApproveDeleteRemoveDayRemoteWork,
    infoApproveDeleteRemoveMarcacaoFerias,
  } = useSelector((state) => state.portalrhReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [requestsValid, setRequestsValid] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();
  const { requestsWithType, isLoadingRequests } = useSelector(
    (state) => state.portalrhReducer
  );

  useEffect(() => {
    const updateRequests = (info) => {
      const updatedRequestsValid = { ...requestsValid };

      Object.keys(updatedRequestsValid).forEach((key) => {
        updatedRequestsValid[key] = updatedRequestsValid[key].map((request) => {
          if (request.id === info[0].id) {
            return info[0];
          }
          return request;
        });
      });

      if (info[0].state === "Anulado") {
        setmessageToast("O seu pedido foi Cancelado");
      }

      setRequestsValid(updatedRequestsValid);
      callGetRequestsByUsername(searchFilter, 1);
      dispatch(setapproveDeleteRemoveDayRemoteWork({}));
      dispatch(setapproveDeleteRemoveMarcacaoFerias({}));
    };

    if (Object.keys(infoApproveDeleteRemoveDayRemoteWork).length > 0) {
      updateRequests(infoApproveDeleteRemoveDayRemoteWork);
    } else if (Object.keys(infoApproveDeleteRemoveMarcacaoFerias).length > 0) {
      updateRequests(infoApproveDeleteRemoveMarcacaoFerias);
    } else {
      setRequestsValid(requestsWithType);
    }
  }, [
    infoApproveDeleteRemoveMarcacaoFerias,
    infoApproveDeleteRemoveDayRemoteWork,
    requestsWithType,
  ]);
  useEffect(() => {
    setRequestsValid(requestsWithType);
  }, [requestsWithType]);

  const checkValidateProc = () => {
    switch (searchFilter) {
      case "absenceRecord":
      case "extraTime":
      case "insertPresence":
      case "pagamentosBolsa":
      case "remoteWork":
      case "marcacaoFerias":
      case "vacationsManagement":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    callGetRequestsByUsername(searchFilter, 1, makeUpdate);
  }, [makeUpdate]);

  useInterval(() => {
    callGetRequestsByUsername(searchFilter, 1);
  }, 1000 * 60);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "id",
      text: t("portalrh.requestTable.requestId"),
      sort: true,
    },
    {
      dataField: "auth_designation",
      text: t("portalrh.requestTable.type"),
      sort: true,
    },
    {
      dataField: "creation_date",
      text: t("portalrh.requestTable.date"),
      sort: true,
    },
    {
      dataField: "change_type",
      text: t("portalrh.requestTable.type"),
      sort: true,
      hidden: true,
    },
    {
      dataField: "state",
      text: t("portalrh.requestTable.state"),
      sort: true,
      formatter: (cellContent, row) => {
        return t(`portalrh.workDays.${row.state}`);
      },
    },
  ];

  const handleRowClick = (row) => {
    if (searchFilter === "remoteWork") {
      handleOpenModalRemoteWork(row);
    } else if (
      searchFilter.trim() === "marcacaoFerias" ||
      searchFilter.trim() === "vacationsManagement"
    ) {
      handleOpenModalMarcacaoFerias(row);
    } else {
      handleOpenFlowModal(row);
    }
  };

  const mobileColumns = [
    {
      dataField: "info",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const headers = formatExtraData.headers;
        return (
          <div>
            <br />
            {headers.map((header, index) => {
              const key = header.dataField;
              if (key && row[key] !== undefined && !header.hidden) {
                const value =
                  typeof row[key] === "boolean"
                    ? row[key].toString()
                    : row[key];
                return (
                  <div key={index}>
                    <b>{header.text}: </b>
                    {value}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
      formatExtraData: {
        headers: columns,
      },
    },
    {
      dataField: "state",
      text: "",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRowClick(row);
              }}
            >
              Detalhe
            </a>
          </div>
        );
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleRowClick(row);
    },
  };

  useEffect(() => {
    let filteredData = [];

    const stateToKeyMap = {
      [RequestState.APPROVED]: "Aprovados",
      [RequestState.TRATADOS]: "Pendentes",
      [RequestState.PENDING]: "Por Aprovar",
      [RequestState.REJECTED]: "Recusados",
    };

    const keys = stateToKeyMap[genericRequestState];
    if (keys) {
      filteredData = requestsValid[keys];
    }
    setFilteredRequests(filteredData);
  }, [searchFilter, genericRequestState, requestsValid]);

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
        <ToolkitProvider
          keyField="id"
          data={
            filteredRequests && filteredRequests.length > 0
              ? filteredRequests.sort((a, b) => b.id - a.id)
              : []
          }
          columns={isMobile ? mobileColumns : columns}
          search
        >
          {(props) => (
            <div>
              <div className="sticky-tabs">
                <div style={{ display: "flex" }}>
                  <SearchBar {...props.searchProps} placeholder={t("Search")} />
                </div>
                <Tabs
                  id="authorizationTabs"
                  defaultActiveKey={genericRequestState}
                  className="custom-tabs"
                  activeKey={genericRequestState}
                  onSelect={(k) => dispatch(setGenericRequestState(k))}
                >
                  <Tab
                    eventKey={RequestState.PENDING}
                    title={t("portalrh.requests.pendingRequests")}
                  ></Tab>
                  <Tab
                    eventKey={RequestState.APPROVED}
                    title={t("portalrh.requests.approvedRequests")}
                  ></Tab>
                  <Tab
                    eventKey={RequestState.REJECTED}
                    title={t("portalrh.requests.refusedRequests")}
                  ></Tab>
                </Tabs>
              </div>
              <Spinner spinning={isLoadingRequests} wrapper />
              {!isLoadingRequests && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("portalrh.personalData.noRequests")}
                  rowEvents={rowEvents}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default withNamespaces()(PersonalAreaRequestTable);
