import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CreateForm from "~/components/CreateForm";

const NativeProcessesWrapper = (props) => {
  const {
    isModal,
    className,
    handleHeaderName,
    handleClose,
    formFromProcess,
    heightModal,
  } = props;
  const { formdata } = useSelector((state) => state.processesReducer);
  const myRef = useRef(null);
  const { focusElement } = useSelector((state) => state.iflowReducer);
  const [formtosendJsonData, setformtosendJsonData] = useState({});

  const removejson = (form) => {
    if (typeof form !== "object" && form?.includes(`{"form" :`)) {
      let arr = form.split(`{"form" :`);
      let re = /\"\" style=\"/g;
      let transformedString = (`{"form" :` + arr[1]).replace(re, '" style=');
      //Remove All Line Breaks from a String
      transformedString = transformedString
        .replace(/\\/g, "\\\\")
        .replace(/(\r\n|\n|\r|\t)/gm, "");

      return JSON.parse(transformedString);
    } else {
      return form;
    }
  };

  useEffect(() => {
    const dataToStore = JSON.parse(localStorage.getItem("dataToStore"));
    if (dataToStore !== null) {
      if (!focusElement) {
        myRef.current.scrollIntoView();
      }
      const transformedString = removejson(dataToStore.formdata);
      setformtosendJsonData(transformedString);
    } else if (formFromProcess) {
      if (!focusElement) {
        myRef.current.scrollIntoView();
      }
      const transformedString = removejson(formFromProcess);
      setformtosendJsonData(transformedString);
    } else {
      if (formdata) {
        if (!focusElement) {
          myRef.current.scrollIntoView();
        }
        const transformedString = removejson(formdata);
        setformtosendJsonData(transformedString);
      }
    }
  }, [formdata, formFromProcess, focusElement]);

  return (
    <div
      ref={myRef}
      style={{
        // display: "flex",
        // flexDirection: "column",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* {Object.keys(formtosendJsonData).length > 0 && ( */}
      <CreateForm
        jsonData={formtosendJsonData}
        isModal={isModal}
        className={className}
        handleHeaderName={handleHeaderName}
        handleClose={handleClose}
        heightModal={heightModal}
      />
      {/* )} */}
    </div>
  );
};
export default NativeProcessesWrapper;
