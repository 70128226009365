import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getCollaboratorData } from "~/store/ducks/collaboratorPersonalData/actionTypes";
import {
  getRHAdminConfiguration,
  setDispRecibos,
  getDispRecibos,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { Form, Col, Row, Button } from "react-bootstrap";
import oauth from "~/utils/oauth";
import {
  getIflowFormdata,
  postAdmissaoCollab,
  getCompaniesNewCollab,
  setAdmissaoCollabMessage,
  setAdmissaoCollabMessageAlreadyExists,
} from "store/ducks/processes/actionTypes";
import FlowModal from "~/components/FlowModal";
import AddButton from "~/components/Buttons/AddButton";
import DropdownComp from "~/components/Dropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "~/pages/Easypay/components/Modal";
import { FaPlus } from "react-icons/fa";
import Toast from "react-bootstrap/Toast";
import { ButtonBase } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function TableRhCollaborator({ onTakeNavOnBolean, userPortal, t }) {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [collaboratorsFiltered, setCollaboratorsFiltered] = useState([]);
  const [rhadminConfig, setrhadminConfig] = useState({});
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [disableGreyedOut, setDisableGreyedOut] = useState(true);
  const [newCollab, setnewCollab] = useState({
    nome: "",
    email: "",
    empresa: "",
    greyedout: "",
  });
  const [cleanDropdownCollabs, setcleanDropdownCollabs] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastClass, setToastClass] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const dispatch = useDispatch();
  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const { collaboratorData } = useSelector((state) => state.personalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const {
    rhadminConfiguration,
    alertDispRecibos,
    isLoadingDispRecibos,
    allowDispRecibos,
  } = useSelector((state) => state.rhadminConfigurationReducer);
  const { companiesNewCollab, newCollabMessage, newCollabAlreadyExists } =
    useSelector((state) => state.processesReducer);

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOrganizationById(user?.organization?.id));
    }
    dispatch(getCollaborators());
    dispatch(getCollaboratorData());
    dispatch(getCompaniesNewCollab());
  }, [dispatch]);

  useEffect(() => {
    if (alertDispRecibos != "") {
      setOpenAlert(true);
      if (alertDispRecibos === "success") {
        setMessageAlert(t(`portalrh.rhadmin.dispRecibos${alertDispRecibos}`));
      } else {
        setMessageAlert(t(`portalrh.rhadmin.dispRecibos${alertDispRecibos}`));
      }
      setTypeAlert(alertDispRecibos);
    }
  }, [alertDispRecibos]);

  useEffect(() => {
    let toastConfig = null;
    if (newCollabMessage && newCollabMessage.length > 0) {
      toastConfig = {
        showToast: true,
        toastClass: "bg-success",
        toastMessage: t("portalrh.rhadmin.newCollabMessage"),
      };
    } else if (newCollabAlreadyExists) {
      toastConfig = {
        showToast: true,
        toastClass: "bg-danger",
        toastMessage: t("portalrh.rhadmin.newCollabMessageAlreadyExists"),
      };
    }

    if (toastConfig) {
      setShowToast(toastConfig.showToast);
      setToastClass(toastConfig.toastClass);
      setToastMessage(toastConfig.toastMessage);
    } else {
      setShowToast(false);
    }
  }, [newCollabMessage, newCollabAlreadyExists]);

  useEffect(() => {
    if (showToast)
      setTimeout(() => {
        setShowToast(false);
        dispatch(getCollaborators());
        dispatch(getCollaboratorData());
        if (!newCollabAlreadyExists) {
          handlebackbuttonModal();
        }
        dispatch(setAdmissaoCollabMessage(null));
        dispatch(setAdmissaoCollabMessageAlreadyExists(false));
      }, 5000);
  }, [showToast]);

  useEffect(() => {
    if (Object.keys(organization).length > 0)
      dispatch(getRHAdminConfiguration(organization?.id));
  }, [organization]);

  useEffect(() => {
    if (Object.keys(rhadminConfiguration).length > 0) {
      setrhadminConfig(rhadminConfiguration.body);
    }
  }, [rhadminConfiguration]);

  //adding new propertie lepPssNum and filtering
  useEffect(() => {
    if (collaboratorData.length > 0 && collaborators.length > 0) {
      for (let i = 0; i < collaboratorData.length; i++) {
        for (let k = 0; k < collaborators.length; k++) {
          if (
            collaboratorData[i].lepNum === collaborators[k].funcionarioNum &&
            collaboratorData[i].lepEmpCode === collaborators[k].codEntidade
          ) {
            collaborators[k].pssNum = collaboratorData[i].lepPssNum;
            let newCollab = collaborators.filter((e) => e.funcionarioNum);
            setCollaboratorsFiltered(newCollab);
          }
        }
      }
    }
  }, [collaboratorData, collaborators]);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "username",
      text: "Username",
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "funcionarioNum",
      text: t(`portalrh.NavBar.collaboratorNum`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "nome",
      text: t(`portalrh.NavBar.Name`),
      sort: true,
      style: { fontSize: "12px" },
    },
  ];

  const handleRowClick = (row) => {
    onTakeNavOnBolean(true, row);
  };

  const mobileColumns = [
    {
      dataField: "info",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const headers = formatExtraData.headers;
        return (
          <div>
            <br />
            {headers.map((header, index) => {
              const key = header.dataField;
              if (key && row[key] !== undefined && !header.hidden) {
                const value =
                  typeof row[key] === "boolean"
                    ? row[key].toString()
                    : row[key];
                return (
                  <div key={index}>
                    <b>{header.text}: </b>
                    {value}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
      formatExtraData: {
        headers: columns,
      },
    },
    {
      dataField: "state",
      text: "",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRowClick(row);
              }}
            >
              Detalhe
            </a>
          </div>
        );
      },
    },
  ];

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleRowClick(row);
    },
  };

  const closeModal = () => {
    setShowFlowModal(false);
  };

  const handleClickStartFlow = (val) => {
    if (val) {
      const payload = {
        url:
          "/inicio_flow.jsp?" +
          "flowid=" +
          rhadminConfig.flowId +
          "&Authorization=" +
          oauth.getAccessToken(),
      };
      dispatch(getIflowFormdata(payload));
      setShowFlowModal(true);
      setCleanDropdown(true);
    } else {
      setshowModal(true);
      //dispatch(getGreyedout());
    }
  };

  const renderFilterCompany = () => {
    if (Array.isArray(companiesNewCollab)) {
      return companiesNewCollab.map((company) => ({
        label: company?.designacao,
        value: company?.code,
        selected: false,
      }));
    } else {
      return [];
    }
  };

  const handlebackbuttonModal = () => {
    setshowModal(false);
    cleanfield();
  };

  const cleanfield = () => {
    setcleanDropdownCollabs(true);
    setDisableGreyedOut(true);
    setnewCollab({
      nome: "",
      email: "",
      empresa: "",
      greyedout: "",
    });
  };

  const validateNewCollab = () => {
    for (const key in newCollab) {
      if (newCollab[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleCreateNewUser = () => {
    if (validateNewCollab()) {
      const payload = {
        numero: newCollab.greyedout,
        entidadeCode: newCollab.empresa,
        nome: newCollab.nome,
        dataInicioCargo: new Date().toISOString().split("T")[0],
        validacao: false,
        email: newCollab.email,
        user: newCollab.email,
      };
      dispatch(postAdmissaoCollab(payload));
    }
  };

  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setnewCollab((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
  };

  const handleEmailChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setnewCollab((prevCategory) => ({
      ...prevCategory,
      email: event.target.value,
    }));
  };

  const handleGreyedout = (event) => {
    event.persist(); // Mantém o evento sintético original

    setnewCollab((prevCategory) => ({
      ...prevCategory,
      greyedout: event.target.value,
    }));
  };

  const handleEmpresaChange = (empresa) => {
    setnewCollab((prevCategory) => ({
      ...prevCategory,
      greyedout: "",
    }));
    const selectedEmpresa = companiesNewCollab.find(
      (empresas) => empresas.code === empresa
    );
    if (selectedEmpresa) {
      const sequencia = selectedEmpresa.sequencia;
      if (sequencia != null) {
        setnewCollab((prevCategory) => ({
          ...prevCategory,
          greyedout: +selectedEmpresa.maxNum + 1,
        }));
        setDisableGreyedOut(true);
      } else {
        setDisableGreyedOut(false);
      }
    }
    setnewCollab((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
  };

  const toastResult = () => {
    return (
      <Toast style={{ maxWidth: "none", width: "100%" }}>
        <Toast.Body className={toastClass} style={{ color: "white" }}>
          {toastMessage}
        </Toast.Body>
      </Toast>
    );
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          className="inputsLiftCrud"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {showToast && toastResult()}
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto", fontSize: "16px", color: "#4c4b4b" }}
          >
            {t("portalrh.rhadmin.empresa")}:
          </label>
          <DropdownComp
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownCollabs}
            resetDropdown={cleanDropdownCollabs}
          />
        </div>
        <div
          className="inputsLiftCrud"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <label
            htmlFor={`greyedout-input`}
            className="labelsLiftCrud"
            style={{ width: "auto", fontSize: "16px", color: "#4c4b4b" }}
          >
            {t("portalrh.rhadmin.code")}:
          </label>
          <input
            id={`greyedout-input`}
            className="form-control"
            type="text"
            value={newCollab?.greyedout}
            onChange={handleGreyedout}
            disabled={disableGreyedOut}
            required
          />
        </div>
        <div
          className="inputsLiftCrud"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto", fontSize: "16px", color: "#4c4b4b" }}
          >
            {t("portalrh.rhadmin.nome")}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={newCollab?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
        <div
          className="inputsLiftCrud"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <label
            htmlFor={`email-input`}
            className="labelsLiftCrud"
            style={{ width: "auto", fontSize: "16px", color: "#4c4b4b" }}
          >
            {t("portalrh.rhadmin.email")}:
          </label>
          <input
            id={`email-input`}
            className="form-control"
            type="text"
            value={newCollab?.email}
            onChange={handleEmailChange}
            required
          />
        </div>
      </div>
    );
  };

  function createEditBody() {
    return (
      <div
        className="lift-modal-body-wrapper"
        style={{ minWidth: isMobile ? "fit-content" : "400px" }}
      >
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t("portalrh.rhadmin.btnVoltar")}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "green" }}
              className="card-button-green active"
              onClick={() => handleCreateNewUser()}
            >
              {t("portalrh.rhadmin.btnCriar")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const handleClick = () => {
    if (userPortal !== undefined) {
      const payload = {
        empCode: userPortal.codEntidade,
        userId: userPortal.id,
      };
      dispatch(getDispRecibos(payload));
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setDispRecibos(""));
    setOpenAlert(false);
    setMessageAlert("");
    setTypeAlert("");
  };

  const rowStyle = { cursor: "pointer" };
  return (
    <div className="custom-table custom-table-small cursor-pointer">
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingDispRecibos}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {collaboratorsFiltered.length > 0 ? (
        <ToolkitProvider
          keyField="id"
          data={collaboratorsFiltered}
          columns={isMobile ? mobileColumns : columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ marginBottom: "20px" }}>
                {rhadminConfig && rhadminConfig != null && (
                  <Row>
                    {!isMobile && (
                      <Col lg="3" sm="12">
                        <SearchBar
                          {...props.searchProps}
                          placeholder={t("general.search")}
                        />
                      </Col>
                    )}
                    <Col
                      style={
                        isMobile
                          ? {
                              display: "flex",
                              placeContent: "space-between",
                              alignItems: "center",
                            }
                          : { display: "flex", gap: "2rem" }
                      }
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "#4484f4",
                            borderColor: "#4484f4",
                          }}
                        >
                          <FaPlus style={{ paddingRight: "5px" }} />
                          Adicionar
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleClickStartFlow(true)}
                          >
                            {t("portalrh.rhadmin.onboarding")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleClickStartFlow(false)}
                          >
                            {t("portalrh.rhadmin.colab")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {allowDispRecibos && (
                        <Button
                          variant="outline-primary"
                          onClick={handleClick}
                          className="custom-button"
                        >
                          {" "}
                          {t("portalrh.rhadmin.dispRecibos")}
                        </Button>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
              <hr />
              {isMobile && (
                <div>
                  <SearchBar
                    {...props.searchProps}
                    placeholder={t("general.search")}
                  />
                </div>
              )}
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEventEditMod}
                striped
                hover
                condensed
                bootstrap4
                noDataIndication={t("general.dataNotFound")}
                rowStyle={rowStyle}
                pagination={
                  collaborators.length > 10
                    ? paginationFactory(pagination)
                    : null
                }
              />
            </div>
          )}
        </ToolkitProvider>
      ) : null}
      <FlowModal
        showModal={showFlowModal}
        closeModal={closeModal}
        isPortal={true}
      />
      <Modal showModal={showModal} bodyContent={createEditBody()} />
    </div>
  );
}

export default withNamespaces()(TableRhCollaborator);
